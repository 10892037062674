import styled from "styled-components";
import {themeColor, themeFontSize} from "ui/theme";

export const WaterQualityViewStyled = styled.div`
  padding: 24px 50px;
`;

export const Link = styled.a`
    line-height: 1;
    font-weight: 500 !important;
    text-decoration: underline;
    display: block;
    padding: 15px 0 !important;
    margin: bottom
`;

export const Row = styled.div`
  overflow-x:auto;
  display: flex;
  flex-wrap:wrap;
  ${Link} {
    font-size: ${themeFontSize("biggest")};
    color: ${themeColor("primary")};
  }
`;

export const PrintBtn = styled.button`
	border-radius: 0,
	background: "#eb7c32",
	border: "1px solid transparent",
  justify-content: "center",
	cursor: "pointer",
  color: "#fff",
	width: "100%",
	max-width: "178px",
	padding: "11px",
	font-size: "14px",
	line-height: 1,
	margin-top: "-37px",
`;


export const Text = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #191919;
    text-align: center;
    margin: 0 auto 35px;
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  max-height: 31px;
  width:auto;
  margin-right:20px;
  height:31px;
  font-weight:600;
  margin-bottom: 37px;
  svg{
    height:100%;
    width: auto;
  }
`

export const Wrapper = styled.div`
      display: flex;
      justify-content: center;
      padding-top: 20px;
`



export const Container = styled.div<{
	width?: number;
	textAlign?: string;
	display?: string;
}>`
	width: 100px;

	button {
		border: 0;
		border-radius: 4px;
		background: hotpink;
		padding: 11px;
		font-size: 14px;
		line-height: 1;
		height: fit-content;
		margin-bottom: 37px;
		border-radius: 0;
		background: #1c6eac;
		font-size: 14px;
	}

	button:hover {
		cursor: pointer;
	}

	button::after {
		right: 6px;
		top: 4px;
		content: "\2191";
	}

	button.expanded::after {
		content: "\2193";
	}
`;
export const Btn = styled.button`
	border: 0;
	border-radius: 4px;
	position: relative;
	padding: 11px;
	font-size: 14px;
	line-height: 1;
	border-radius: 0;
	background: #1c6eac;
	width: 200px;
	margin-top: -38px;
	span {
		color: #fff;
		font-size: 14px;
	}
`;

export const Select = styled.div`
	margin-top: -48px;
	ul.options {
		border: 1px solid #fefefe;
		display: none;
		list-style: none;
		padding: 4px 0;
		margin-top: -4px;
		position: absolute;
		width: 200px;
		margin-top: 1px;
		background-color: #fefefe;
	}

	ul.show {
		display: block;
	}

	ul.options li {
		padding: 6px 10px;
		color: #1c6eac;
	}

	ul.options li:active,
	ul.options li:focus,
	ul.options li:hover,
	ul.options li[aria-selected="true"] {
		background: #daebf2;
		cursor: pointer;
	}
`;