import React from "react";

export const HelpIcon = () => {
  return (
    <svg
      id="help_icon"
      enableBackground="new 0 0 64 64"
      height="512"
      viewBox="0 0 64 64"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g id="LWPOLYLINE">
          <g>
            <path d="m31.321 38.904c-.006 0-.012 0-.018 0-1.104-.01-1.992-.913-1.982-2.018l.009-1c0-.057.003-.115.009-.172.277-2.909 2.208-4.692 3.761-6.125.526-.486 1.024-.945 1.448-1.424.517-.583 1.267-1.777.478-3.218-.909-1.664-3.129-2.135-4.854-1.74-1.802.412-2.466 1.951-2.702 2.831-.286 1.067-1.383 1.7-2.45 1.415-1.067-.286-1.7-1.383-1.415-2.45.797-2.977 2.865-5.052 5.673-5.695 3.779-.863 7.585.664 9.256 3.719 1.391 2.544 1.01 5.529-.995 7.791-.557.628-1.152 1.178-1.728 1.709-1.435 1.325-2.332 2.211-2.484 3.49l-.008.903c-.008 1.101-.902 1.984-1.998 1.984z" />
          </g>
        </g>
        <g id="POINT">
          <g>
            <path d="m31.32 44.9c-.52 0-1.04-.21-1.41-.58-.38-.37-.59-.89-.59-1.42 0-.52.21-1.04.59-1.41.74-.74 2.08-.74 2.82 0 .38.37.59.89.59 1.41 0 .53-.21 1.04-.58 1.42-.38.37-.89.58-1.42.58z" />
          </g>
        </g>
        <g id="CIRCLE_2_">
          <g>
            <path d="m31.923 57.997c-2.275 0-4.53-.296-6.703-.881-9.364-2.519-16.696-10.276-18.679-19.763-2.021-9.665 1.709-19.825 9.504-25.884 4.506-3.503 10.189-5.433 16.003-5.433 4.025 0 8.022.936 11.56 2.705 8.737 4.371 14.382 13.503 14.382 23.266 0 7.966-3.759 15.644-10.055 20.538-4.522 3.516-10.209 5.452-16.012 5.452zm.126-47.961c-4.929 0-9.741 1.63-13.548 4.591-6.597 5.128-9.755 13.727-8.044 21.907 1.678 8.025 7.881 14.588 15.803 16.719 1.834.493 3.74.744 5.663.744h.001c4.918 0 9.732-1.637 13.557-4.61 5.329-4.142 8.51-10.639 8.51-17.38 0-8.261-4.777-15.989-12.171-19.688-2.986-1.493-6.364-2.283-9.771-2.283z" />
          </g>
        </g>
      </g>
    </svg>
  );
};
