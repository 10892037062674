import React from "react";
import {Title} from "ui/base/Title";
import {FacilityInspectionTable} from "ui/blocks/resources/FacilityInspectionTable";
import {
    MonitoringRow,
    MonitoringCol,
    MonitoringViewStyled, Text
} from "./MonitoringViewStyled";
import {StormwaterMonitoringCol} from "ui/blocks/portal/StormwaterMonitoringCol/";
import LeaderInspection from "ui/blocks/monitoring/LeaderInspection/LeaderInspection";

const MonitoringView = () => {
    return (
        <MonitoringViewStyled>
            <MonitoringRow>
                <StormwaterMonitoringCol background={'inherit'}/>
            </MonitoringRow>
            <MonitoringRow>
                <MonitoringCol>
                    <Title position="left" uppercase weight="600" size="h5">
                        Facility Monitoring Forms
                    </Title>
                    <Text>(The facility shall complete the following forms based on the corresponding frequency.)</Text>
                    <FacilityInspectionTable/>
                </MonitoringCol>
                <LeaderInspection/>
            </MonitoringRow>
        </MonitoringViewStyled>
    );
};

export default MonitoringView;
