import React from "react";
import {HeaderStyled, Title} from "ui/blocks/Header/HeaderStyled";
import {Search} from "ui/blocks/Header/Search";
import {Actions} from "ui/blocks/Header/Actions";

interface HeaderProps {
    title: string;
}

const Header = ({title}: HeaderProps) => {
    return (
        <HeaderStyled>
            <Search/>
            <Title>{title}</Title>
            <Actions/>
        </HeaderStyled>
    );
};

export default Header;
