import React, {useEffect, useState} from "react";
import {
    Avatar,
    Name,
    PeoplesItem,
    ResourcesPeoplesStyled,
    Position
} from "./ResourcesPeoplesStyled";
import api from 'api/index';

type data = {
    avatar: string,
    fax: string,
    first_name: string,
    id: number,
    last_name: string,
    middle_name: string,
    physical_address: string,
    position: string,
    postal_address: string,
    profile_link: string,
}

const ResourcesPeoples = () => {
    const [data , setData] = useState<data[]>()
    const [loading , setLoading] = useState(false)
    const [error , setError] = useState(null)

    useEffect(() => {
        api.get('team')
            .then(res => res)
            .then(
                (result) => {
                    setLoading(true);
                    setData(result.data);
                },
                (error) => {
                    setLoading(true);
                    setError(error.error);
                }
            )
    }, [])
    if (error) {
        return <div>Error: {error}</div>;
    } else if (!loading) {
        return <div>Loading...</div>;
    } else if (data){
        return (
            <ResourcesPeoplesStyled>
                {data.map((item) => (
                    <a href={item.profile_link} target='_blank'>
                        <PeoplesItem key={item.id}>
                            <Avatar>
                                <img src={item.avatar} alt="avatar"/>
                            </Avatar>
                            <Name>{item.first_name} {item.middle_name} {item.last_name}</Name>
                            <Position>{item.position}</Position>
                        </PeoplesItem>
                    </a>
                ))}
            </ResourcesPeoplesStyled>
        );
    }  else {
        return <div></div>
    }
};

export default ResourcesPeoples;
