import React, {useState} from "react";
import {
    NavIcon,
    NavigationStyled,
    NavItem,
    NavList,NavClose
} from "./NavigationStyles";
import {routesPath} from "routes";
import {User} from "ui/blocks/Sidebar/User";
import {NavLink} from "react-router-dom";
import {
    FacilityInfoIcon,
    HomeIcon,
    MonitoringIcon,
    MyAccountIcon,
    ResourcesIcon,
    WaterQualityIcon
} from "ui/icons";
import {LogOutBtn} from "ui/blocks/Header/LogOutBtn";
import {Search} from "ui/blocks/Header/Search";

const Navigation = () => {
    const [activeMenu, setActiveMenu] = useState(false)
    const [target, setTarget] = useState<any>()

    return (
        <NavigationStyled>

            <NavLink to={routesPath.profile}>
                <User/>
            </NavLink>
            <NavList
                activeMenu={activeMenu}
                onClick={(event) => {
                    if (window.innerWidth < 1024) {
                        if (event.target != target) {
                            setActiveMenu(true)
                        } else setActiveMenu(false)
                    }
                }}
            >
                <NavClose onClick={(e)=> {
                    setTarget(e.target)
                    setActiveMenu(!activeMenu)
                }}
                          isActive={activeMenu}/>

                {window.innerWidth < 1024 ? <NavItem  isActive={activeMenu}>
                        <Search/>
                    </NavItem> : null}

                <NavLink to={routesPath.home}>
                    {(nav) => (
                        <NavItem isActive={nav.isActive}>
                            <NavIcon height={31}>
                                <HomeIcon/>
                            </NavIcon>
                            Home
                        </NavItem>
                    )}
                </NavLink>
                <NavLink to={routesPath.info}>
                    {(nav) => (
                        <NavItem isActive={nav.isActive}>
                            <NavIcon height={30}>
                                <FacilityInfoIcon/>
                            </NavIcon>
                            Facility info
                        </NavItem>
                    )}
                </NavLink>
                <NavLink to={routesPath.monitoring}>
                    {(nav) => (
                        <NavItem isActive={nav.isActive}>
                            <NavIcon height={30}>
                                <MonitoringIcon/>
                            </NavIcon>
                            Monitoring and <br/> Inspections
                        </NavItem>
                    )}
                </NavLink>
                <NavLink to={routesPath.waterQuality}>
                    {(nav) => (
                        <NavItem isActive={nav.isActive}>
                            <NavIcon height={33}>
                                <WaterQualityIcon/>
                            </NavIcon>
                            Water Quality <br/> Data
                        </NavItem>
                    )}
                </NavLink>
                <NavLink to={routesPath.resources}>
                    {(nav) => (
                        <NavItem isActive={nav.isActive}>
                            <NavIcon height={36}>
                                <ResourcesIcon/>
                            </NavIcon>
                            Resources
                        </NavItem>
                    )}
                </NavLink>
                <NavLink to={routesPath.account}>
                    {(nav) => (
                        <NavItem isActive={nav.isActive}>
                            <NavIcon height={31}>
                                <MyAccountIcon/>
                            </NavIcon>
                            My Profile
                        </NavItem>
                    )}
                </NavLink>
                {window.innerWidth < 1024 ? <NavLink to={routesPath.logout}>
                    {(nav) => (
                        <NavItem isActive={nav.isActive}>
                            <LogOutBtn background={"#1c6eac"} color={'#fff'}/>
                        </NavItem>
                    )}
                </NavLink> : null}
            </NavList>
        </NavigationStyled>
    );
};

export default Navigation;
