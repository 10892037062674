import React, {ReactNode} from "react";
import {
    ColStyled,
} from "./ColStyled";

interface ColProps {
    children: ReactNode;
    width?: number;
    textAlign?: string;
    display?: string;
}

const Col = ({width, children, textAlign, display}: ColProps) => {
    return (
        <ColStyled width={width} textAlign={textAlign} display={display}>
            {children}
        </ColStyled>
    );
};

export default Col;
