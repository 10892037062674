import React from "react";
import {ActionItem, ActionsStyled, NavIcon} from "./ActionsStyled";
import {NavLink} from "react-router-dom";
import {routesPath} from "routes";
import {HelpIcon} from "ui/icons";
import {LogOutBtn} from "ui/blocks/Header/LogOutBtn";

export const Actions = () => {
    return (
        <ActionsStyled>
            <NavLink to={routesPath.contactUs}>
                <ActionItem hidden>
                    Get Help
                    <NavIcon>
                        <HelpIcon/>
                    </NavIcon>
                </ActionItem>
            </NavLink>
            <LogOutBtn background={"white"}/>
        </ActionsStyled>
    );
};