import {AUTH_API_URL, CLIENT_ID} from "./index";

function callSignin(){
    const uniqueState : number = Math.random();
    window.localStorage.setItem('authRequestState', String(uniqueState));
    window.localStorage.setItem('isAuth', 'isAuth');
    window.location.assign(`${AUTH_API_URL}/authorize?response_type=code&client_id=${CLIENT_ID}&state=${uniqueState}&redirect_uri=${window.location.origin}/redirect`);
}

function callSignout(){
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('isAuth');
    window.location.assign(`${AUTH_API_URL}/logout?client_id=${CLIENT_ID}&redirect_uri=${window.location.origin}/`);
}

export default {
    callSignin,
    callSignout,
}
