import styled from "styled-components";
import {themeColor, themeFontSize} from "ui/theme";

export const HeaderStyled = styled.div`
  background-color: #fefefe;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 52px;
`;
export const Title = styled.div`
  color: ${themeColor("grey")};
  font-size: ${themeFontSize("big")};
  font-weight: 700;
  position: relative;
  text-align: center;
  width: 33%;
  &:after {
    content: "";
    width: 64px;
    height: 3px;
    background-color: #0e5992;
    position: absolute;
    bottom: -21px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
