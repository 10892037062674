import React, {useEffect, useState} from "react";
import {Title} from "ui/base/Title";
import {
    WaterQualityViewStyled,
    Row,
    Text,
    IconWrapper,
    Select,
    Wrapper,
    Container,
    Btn,
} from "ui/blocks/WaterQuality/WaterQualityView/WaterQualityViewStyled";
import {Table} from "ui/base/Table";
import {Col} from "ui/blocks/WaterQuality/WaterQualityView/Col";
import {DownloadIcon, PrinterIcon, SelectIcon} from "ui/icons";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {WDID} from "mock/WDID";
import store from "ui/redux/store";
import ReactToPrint from "react-to-print";
import {CSVLink} from "react-csv";
import {
    sampleColumn,
    analyticalColumn,
    columnFirstSubheaders,
    tableStaticDate,
    footerStaticRaw,
    footerRaw,
} from "./constants/constants";

import {getSwapedKeysAndValues} from "../../../../helpers/functions";
import {useRequest} from "../hooks/useRequest";
import Message from "./Message/Message";

const DATE = gql`
	query MyQuery($wdid: String!) {
		portal_qry_wq(where: { wdid: { _eq: $wdid } }) {
			report_year
		}
	}
`;

type data = {
    portal_qry_wq: [
        dataItem
    ];
};

type dataItem = {
    collectiondate: string;
    monitoringpoint: string;
    parameter_smarts: string;
    report_year: string;
    result_display: string;
    units: string;
    nal_criteria_type: string;
    nal_value: string;
    nel_criteria_type: string;
    nel_value: string;
    annual_avg: string;
};

type newItem = {
    collectiondate: string;
    monitoringpoint: string;
    params: newItemParams[];
};

type newItemParams = {
    parameter_smarts: string;
    result_display: string;
    units: string;
    annual_avg: string;
    number: number | null;
};

type address = {
    facility_address: string;
    wdid: string;
    facility_name: string;
};

type nalVal = {
    parameter_smarts: string;
    nal_data_params: nalDataParams[];
};

type nalDataParams = {
    nal_criteria_type: string;
    nal_value: string;
};

type TMDLVal = {
    parameter_smarts: string;
    nel_data_params: TMDLDataParams[];
};

type TMDLDataParams = {
    nel_criteria_type: string;
    nel_value: string;
};

function getNalVal(data: data) {
    let result: nalVal[] = [];
    let p_res: nalVal;
    let n_data = data.portal_qry_wq;
    let arr: string[] = [];

    n_data.forEach((item: dataItem) => {
        let isExists: boolean = false;

        if (arr.indexOf(item.parameter_smarts) < 0) {
            arr.push(item.parameter_smarts);
        }

        if (result.length > 0) {
            result.forEach((res, i) => {
                if (
                    item.parameter_smarts === res?.parameter_smarts &&
                    res?.nal_data_params.find((value) => {
                        return item.nal_criteria_type === value.nal_criteria_type;
                    })
                ) {
                    let params: nalDataParams = {
                        nal_criteria_type: item.nal_criteria_type,
                        nal_value: item.nal_value,
                    };

                    result[i].nal_data_params.push(params);
                    isExists = true;
                }
            });
        }

        if (!isExists) {
            let params: nalDataParams = {
                nal_criteria_type: item.nal_criteria_type,
                nal_value: item.nal_value,
            };
            p_res = Object.assign(
                {},
                {
                    parameter_smarts: item.parameter_smarts,
                    nal_data_params: [params],
                }
            );

            result.push(p_res);
        }
    });
    return result;
}

function getTMDLVal(data: data) {
    let result: TMDLVal[] = [];
    let p_res: TMDLVal;
    let n_data = data.portal_qry_wq;
    let arr: string[] = [];

    n_data.forEach((item: dataItem) => {
        let isExists: boolean = false;

        if (arr.indexOf(item.parameter_smarts) < 0) {
            arr.push(item.parameter_smarts);
        }

        if (result.length > 0) {
            result.forEach((res, i) => {
                if (
                    item.parameter_smarts === res?.parameter_smarts &&
                    res?.nel_data_params.find((value) => {
                        return item.nal_criteria_type === value.nel_criteria_type;
                    })
                ) {
                    let params: TMDLDataParams = {
                        nel_criteria_type: item.nel_criteria_type,
                        nel_value: item.nel_value,
                    };

                    result[i].nel_data_params.push(params);
                    isExists = true;
                }
            });
        }

        if (!isExists) {
            let params: TMDLDataParams = {
                nel_criteria_type: item.nel_criteria_type,
                nel_value: item.nel_value,
            };
            p_res = Object.assign(
                {},
                {
                    parameter_smarts: item.parameter_smarts,
                    nel_data_params: [params],
                }
            );

            result.push(p_res);
        }
    });
    return result;
}

function getObj(data: data) {
    let result: newItem[] = [];
    let p_res: newItem;
    let n_data = data.portal_qry_wq;
    let arr: string[] = [];

    n_data.forEach((item: dataItem) => {
        let isExists: boolean = false;

        if (arr.indexOf(item.parameter_smarts) < 0) {
            arr.push(item.parameter_smarts);
        }

        if (result.length > 0) {
            result.forEach((res, i) => {
                if (
                    item.monitoringpoint === res?.monitoringpoint &&
                    item.collectiondate === res?.collectiondate
                ) {
                    if (!result[i].params.find((param)=>param.number === arr.indexOf(item.parameter_smarts))) {
                        let params: newItemParams = {
                            parameter_smarts: item.parameter_smarts,
                            result_display: item.result_display,
                            units: item.units,
                            number: arr.indexOf(item.parameter_smarts),
                            annual_avg: item.annual_avg,
                        };
                        result[i].params.push(params);
                    }
                    isExists = true;
                }
            });
        }

        if (!isExists) {
            let params: newItemParams = {
                parameter_smarts: item.parameter_smarts,
                result_display: item.result_display,
                annual_avg: item.annual_avg,
                units: item.units,
                number: arr.indexOf(item.parameter_smarts),
            };
            p_res = Object.assign(
                {},
                {
                    monitoringpoint: item.monitoringpoint,
                    report_year: item.report_year,
                    collectiondate: item.collectiondate,
                    params: [params],
                }
            );

            result.push(p_res);
        }
    });
    return result;
}

function byDate(field: string) {
    return (a: any, b: any) => {
        return new Date(a[field]) > new Date(b[field]) ? -1 : 1
    };
}

function byField(field: string) {
    return (a: any, b: any) => {
        return a[field] > b[field] ? 1 : -1
    };
}

function num(e: string) {
    if (isNaN(+e)) {
        return e
    } else {
        const newNum = (+e).toFixed(4);
        let str = newNum.toString()
        for (let i = str.length - 1; i >= 0; i--) {
            if (str[i] === '0') {
                str = str.split('').slice(0, i).join('')
            } else break
        }
        if (str[str.length-1] === '.') {
            str = str.slice(0, -1)
        }
        return str
    }
}

const WaterQualityView = () => {
    const [newWDID, setNewWDID] = useState(WDID);
    const [reportYear, setReportYear] = useState("");

    const {REQUEST, setQuery} = useRequest();

    const {loading, error, data, refetch} = useQuery(REQUEST, {
        variables: {wdid: newWDID, report_year: reportYear},
    });
    const {data: selectedDate} = useQuery(DATE, {
        variables: {wdid: newWDID},
    });

    const handleCloseSelect = () => {
        setIsOptionsOpen(false);
    };

    useEffect(() => {
        if (reportYear) {
            setQuery("_eq");
            refetch();
        }
    }, [reportYear]);

    useEffect(() => {
        handleCloseSelect()
        setReportYear('')
    }, [newWDID])

    const sortedAndFiltredDate = Array.from(
        new Set<string>(
            selectedDate?.portal_qry_wq.map((date: any) => date.report_year)
        )
    ).filter(n=>n).sort();

    useEffect(() => {
        if (!reportYear) {
            setReportYear(sortedAndFiltredDate[sortedAndFiltredDate.length - 1])
            setSelectedOption(sortedAndFiltredDate.length - 1)
        }
    }, [sortedAndFiltredDate])

    store.subscribe(() => setNewWDID(store.getState().wdid));
    const componentRef = React.useRef(null);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);

    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleCloseSelect, true);
        return () => {
            window.removeEventListener("scroll", handleCloseSelect, true)
        };
    });

    const setSelectedThenCloseDropdown = (index: number) => {
        setReportYear(sortedAndFiltredDate[index]);
        setSelectedOption(index);
        setIsOptionsOpen(false);
    };

    if (loading) return <Message>Loading...</Message>;
    if (error) return <Message>
        Facility has no data available
    </Message>;

    if (data) {
        let nalVal = getNalVal(data);

        let TMDLVal = getTMDLVal(data);

        const tmdlNel = !!data.portal_qry_wq.find((v: any) => v.nel_criteria_type === 'NEL')
        const tmdlNal = !!data.portal_qry_wq.find((v: any) => v.nel_criteria_type === 'TNAL')

        let newObj = getObj(data);
        let params: string[] = [];

        newObj.forEach((item) => {
            item.params.forEach(({parameter_smarts}) => {
                if (params.indexOf(parameter_smarts) < 0) {
                    params.push(parameter_smarts);
                }
            });
        });

        const tableHeader = [];
        const firstColumnHeader: any = [];
        const secondColumnHeader: any = [];
        const firstColumnSubheader: any = {};
        const secondColumnSubheader: any = {};

        columnFirstSubheaders.map((_, index) => {
            if (!!index) {
                firstColumnHeader.push({label: "", key: sampleColumn + index});
            } else {
                firstColumnHeader.push({
                    label: tableStaticDate.firstHeader,
                    key: sampleColumn + index,
                });
            }
        });

        params.map((_, index) => {
            if (!!index) {
                secondColumnHeader.push({
                    label: "",
                    key: analyticalColumn + index,
                });
            } else {
                secondColumnHeader.push({
                    label: tableStaticDate.secondHeader,
                    key: analyticalColumn + index,
                });
            }
        });

        columnFirstSubheaders.map((subheader, index) => {
            if (!firstColumnSubheader[sampleColumn + index]) {
                firstColumnSubheader[sampleColumn + index] = subheader;
            }
        });

        params.map((subheader, index) => {
            if (!secondColumnSubheader[analyticalColumn + index]) {
                secondColumnSubheader[analyticalColumn + index] = subheader;
            }
        });

        tableHeader[0] = {...firstColumnSubheader, ...secondColumnSubheader};

        const headers = [...firstColumnHeader, ...secondColumnHeader];

        const footerNavigationColumn = 0;

        const tableFooter: any = footerRaw.map((raw) => ({
            [sampleColumn + footerNavigationColumn]: raw,
        }));

        const copyParams = Object.assign({}, params);

        const convertTableDataToCSVFormat = (
            tableHeader: any,
            tableFooter: any
        ) => {
            let CSVBodyData: any[] = [];

            newObj.map(({collectiondate, monitoringpoint, params}) => {
                let rawTable: any = {
                    sampleColumn0: collectiondate,
                    sampleColumn1: monitoringpoint,
                };
                params.map(({parameter_smarts, result_display}: any) => {
                    rawTable[
                        `${analyticalColumn}${
                            getSwapedKeysAndValues(copyParams)[parameter_smarts]
                        }`
                        ] = result_display || "";
                });
                CSVBodyData.push(rawTable);
            });

            return [...tableHeader, ...CSVBodyData, ...tableFooter];
        };

        params.map((param) => {
            let res = "N/A";
            let key = "";
            nalVal.map(({parameter_smarts, nal_data_params}, index: number) => {
                if (param === parameter_smarts) {
                    nal_data_params.map(({nal_criteria_type, nal_value}) => {
                        if (nal_criteria_type === footerStaticRaw.anual) {
                            res = nal_value;
                        }
                        key = `${analyticalColumn}${index}`;
                    });
                }
            });
            if (!tableFooter[1][key]) {
                tableFooter[1][key] = res;
            }
        });

        params.map((param) => {
            let res = "N/A";
            let key = "";
            nalVal.map(({parameter_smarts, nal_data_params}, index: number) => {
                if (param === parameter_smarts) {
                    nal_data_params.map(({nal_criteria_type, nal_value}) => {
                        if (nal_criteria_type === footerStaticRaw.minimum) {
                            res = nal_value;
                        }
                        key = `${analyticalColumn}${index}`;
                    });
                }
            });
            if (!tableFooter[2][key]) {
                tableFooter[2][key] = res;
            }
        });

        params.map((param, index) => {
            let sum: number = 0;
            newObj.map(({params}) => {
                params.forEach(({parameter_smarts, result_display}) => {
                    if (param === parameter_smarts) {
                        sum = sum + (+result_display ? +result_display : 0);
                    }
                });
            });

            if (!tableFooter[0][`${analyticalColumn}${index}`]) {
                tableFooter[0][`${analyticalColumn}${index}`] =
                    param === footerStaticRaw.avarage
                        ? "N/A"
                        : (sum / newObj.length).toFixed(2);
            }
        });

        let address: string = "";
        let facility_names: string = "";

        {
            data.portal_qry_facility_basic.forEach(({facility_address, facility_name}: address) => {
                address = facility_address;
                facility_names = facility_name;
            });
        }

        newObj.sort(byField('monitoringpoint')).reverse()
        newObj.sort(byDate('collectiondate')).reverse()


        params.sort((a?: string, b?: string) => {
                if (a && b) {
                    if (a === 'pH') {
                        return -1
                    }
                    if (b === 'pH') {
                        return 1
                    }
                    if (a === "Total Suspended Solids (TSS)") {
                        return -1
                    }
                    if (b === 'Total Suspended Solids (TSS)') {
                        return 1
                    }
                    if (a === 'Oil and Grease') {
                        return -1
                    }
                    if (b === 'Oil and Grease') {
                        return 1
                    }
                    if (a > b) {
                        return 1;
                    }
                    if (a < b) {
                        return -1;
                    }
                }
                // a должно быть равным b
                return 0;
            }
        )


        return (
            <WaterQualityViewStyled>
                <Row>
                    <Col width={100}>
                        <Title position='left' size='h5'>
                            Water Quality Data Summary
                        </Title>
                    </Col>
                    <Col width={33} textAlign={"left"} display={"flex"}>
                        <IconWrapper>
                            <SelectIcon/>
                            Select
                        </IconWrapper>
                        <Select>
                            <Wrapper>
                                <Container>
                                    <Btn type='button' onClick={toggleOptions}>
										<span>
											{reportYear === ""
                                                ? "Select year"
                                                : `Reporting Year: ${
                                                    sortedAndFiltredDate[selectedOption]
                                                }`}
										</span>
                                    </Btn>

                                    <ul
                                        className={`options ${isOptionsOpen ? "show" : ""}`}
                                        aria-activedescendant={sortedAndFiltredDate[selectedOption]}
                                    >
                                        {sortedAndFiltredDate.map((option, index) => (
                                            <li
                                                id={option}
                                                aria-selected={selectedOption == index}
                                                onClick={() => {
                                                    setSelectedThenCloseDropdown(index);
                                                }}
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                </Container>
                            </Wrapper>
                        </Select>
                    </Col>
                    <Col width={33} textAlign={"left"} display={"flex"}>
                        <IconWrapper>
                            <PrinterIcon/>
                        </IconWrapper>
                        <ReactToPrint
                            content={reactToPrintContent}
                            documentTitle='AwesomeFileName'
                            removeAfterPrint
                            trigger={() => (
                                <button
                                    style={{
                                        borderRadius: 0,
                                        background: "#eb7c32",
                                        border: "1px solid transparent",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        color: "#fff",
                                        width: "100%",
                                        maxWidth: "178px",
                                        padding: "11px",
                                        fontSize: "14px",
                                        lineHeight: 1,
                                        marginTop: "-37px",
                                    }}
                                >
                                    Print Table
                                </button>
                            )}
                        />
                    </Col>

                    <Col width={33} textAlign={"left"} display={"flex"}>
                        <IconWrapper>
                            <DownloadIcon/>
                        </IconWrapper>
                        <CSVLink
                            data={convertTableDataToCSVFormat(tableHeader, tableFooter)}
                            headers={headers}
                            style={{
                                borderRadius: 0,
                                background: "#1c6eac",
                                border: "1px solid transparent",
                                justifyContent: "center",
                                cursor: "pointer",
                                color: "#fff",
                                width: "100%",
                                maxWidth: "178px",
                                padding: "11px",
                                fontSize: "14px",
                                lineHeight: 1,
                                fontWeight: 600,
                                textAlign: "center",
                            }}
                        >
                            Download Raw Data
                        </CSVLink>
                    </Col>
                </Row>
                <div
                    style={{padding: "0 40px 40px", width: "100%"}}
                    ref={componentRef}
                >
                    <Row>

                        <Text>
                            {reportYear && sortedAndFiltredDate[selectedOption] || ""} Stormwater Sampling
                            Results <br/> {facility_names} <br/> {address} <br/>
                            WDID {newWDID}
                        </Text>
                        <Col width={100}>
                            <Table
                                widthCol={20}
                                qtyCol={9}
                                header={
                                    <>
                                        <tr>
                                            <td align={"center"} colSpan={2}>Sample Informations</td>
                                            <td align={"center"} colSpan={params.length}>Analytical Parameters</td>
                                        </tr>
                                        <tr>
                                            <td align={"center"}>Date</td>
                                            <td align={"center"}>Sample Location</td>
                                            {params.map((value) => (
                                                <td align={"center"}>{value}</td>
                                            ))}
                                        </tr>
                                    </>
                                }
                            >
                                <>
                                    {newObj.map((data, index, array) => {
                                        return (
                                            <tr>
                                                {index > 0 &&
                                                array[index - 1].collectiondate ===
                                                data.collectiondate ? null : (
                                                    <td
                                                        align={"center"}
                                                        rowSpan={
                                                            array.filter(
                                                                ({collectiondate}) =>
                                                                    collectiondate === data.collectiondate
                                                            ).length
                                                        }
                                                    >
                                                        {data.collectiondate}
                                                    </td>
                                                )}
                                                <td align={"center"}>{data.monitoringpoint}</td>

                                                {params.map((param) => {
                                                    return (
                                                        <td align={"center"}>
                                                            {data.params.map(({
                                                                                  result_display,
                                                                                  parameter_smarts,
                                                                                  units
                                                                              }) => {
                                                                    return param === parameter_smarts ?
                                                                        `${num(result_display)} ${units}`
                                                                        : ""
                                                                }
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td colSpan={2} align={"right"}>
                                            Average
                                        </td>
                                        {params.map((param) => {
                                            let sum: string = "";
                                            let unit: string = ``;
                                            // let qty: number = 0;
                                            newObj.map(({params}) => {
                                                params.forEach(({parameter_smarts, units, annual_avg}) => {

                                                    if (param === parameter_smarts) {
                                                        unit = units
                                                        sum = annual_avg
                                                    }
                                                });
                                            });

                                            return (
                                                <td align={"center"}>

                                                    {param === "pH"
                                                        ? "N/A"
                                                        : `${num(sum)} ${unit}`}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    {/*<tr>*/}
                                    {/*    <td colSpan={2} align={"right"}>*/}
                                    {/*        Annual NAL Value*/}
                                    {/*    </td>*/}
                                    {/*    {params.map((value) => {*/}
                                    {/*        let res = "N/A";*/}
                                    {/*        nalVal.map((value1) => {*/}
                                    {/*            if (value === value1.parameter_smarts) {*/}
                                    {/*                value1.nal_data_params.map((value2) => {*/}
                                    {/*                    if (value2.nal_criteria_type === "Annual NAL") {*/}
                                    {/*                        res = value2.nal_value;*/}
                                    {/*                    }*/}
                                    {/*                });*/}
                                    {/*            }*/}
                                    {/*        });*/}
                                    {/*        return <td align={"center"}>{res}</td>;*/}
                                    {/*    })}*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td colSpan={2} align={"right"}>
                                            Annual NAL (Instantaneous NAL)
                                        </td>
                                        {params.map((param) => {
                                            let res = "N/A";
                                            nalVal.map(({parameter_smarts, nal_data_params}) => {
                                                if (param === parameter_smarts) {
                                                    nal_data_params.map(({nal_criteria_type, nal_value}) => {
                                                        if (
                                                            nal_criteria_type ===
                                                            "Annual NAL (Instantaneous NAL)"
                                                        ) {
                                                            res = nal_value;
                                                        }
                                                    });
                                                }
                                            });
                                            return <td align={"center"}>{num(res)}</td>;
                                        })}
                                    </tr>
                                    {tmdlNel ?
                                        <tr>
                                            <td colSpan={2} align={"right"}>
                                                TMDL NELs
                                            </td>
                                            {params.map((param) => {
                                                let res = "N/A";
                                                TMDLVal.map(({parameter_smarts, nel_data_params}) => {
                                                    if (param === parameter_smarts) {
                                                        nel_data_params.map(({nel_criteria_type, nel_value}) => {
                                                            if (
                                                                nel_criteria_type ===
                                                                "NEL"
                                                            ) {
                                                                res = nel_value;
                                                            }
                                                        });
                                                    }
                                                });
                                                return <td align={"center"}>{num(res)}</td>;
                                            })}
                                        </tr> :
                                        <></>
                                    }
                                    {tmdlNal ?
                                        <tr>
                                            <td colSpan={2} align={"right"}>
                                                TMDL NALs
                                            </td>
                                            {params.map((param) => {
                                                let res = "N/A";
                                                TMDLVal.map(({parameter_smarts, nel_data_params}) => {
                                                    if (param === parameter_smarts) {
                                                        nel_data_params.map(({nel_criteria_type, nel_value}) => {
                                                            if (
                                                                nel_criteria_type ===
                                                                "TNAL"
                                                            ) {
                                                                res = nel_value;
                                                            }
                                                        });
                                                    }
                                                });
                                                return <td align={"center"}>{num(res)}</td>;
                                            })}
                                        </tr> :
                                        <></>
                                    }
                                </>
                            </Table>
                        </Col>


                    </Row>
                    <Row>
                        <Col width={100}>
                            <p>
                                <h4 style={{
                                    fontSize: '20px',
                                    color: '#eb7c32'
                                }}>Notes:</h4>
                                <br/>
                                DNQ = Detected not quantifiable. A value of 0 is used to calculate the average.<br/>
                                mg/L = milligrams per liter.<br/>
                                N/A = Not applicable.<br/>
                                NA = Not Analyzed.<br/>
                                NAL = Numeric Action Level.<br/>
                                ND = Non-detect values. A value of 0 is used to calculate the average.<br/>
                                s.u. = Standard pH Units.<br/>
                                NEL = Numeric Effluent Limit. Values in bold, red text are above the NEL Value.<br/>
                                <br/>
                                Reference:<br/>
                                1. NAL values per NPDES Industrial General Permit, Order 2014-0057-DWQ, Table 2, Section
                                XI.B.<br/>
                                2. NEL values per Industrial General Permit, Order 2014-0057-DWQ as amended by Order
                                2015-0122-DWQ, Attachment E, Table 2.<br/>
                            </p>
                        </Col>
                    </Row>
                </div>
            </WaterQualityViewStyled>
        );
    } else
        return (
            <Message>
                Facility has no data available
            </Message>
        );
};

export default WaterQualityView;
