import {useState} from "react";
import gql from "graphql-tag";

export const useRequest = () => {

    const [query, setQuery] = useState('_neq')

    let REQUEST = gql`
	query MyQuery($wdid: String!, $report_year: String!) {
		portal_qry_wq(
			where: { wdid: { _eq: $wdid }, report_year: { ${query}: $report_year } }
			order_by: {
				report_year: asc,
				collectiondate: asc
			}
		) {
			collectiondate
			monitoringpoint
			parameter_smarts
			report_year
			result_display
			units
			nal_criteria_type
			nal_value
            nel_criteria_type
            nel_value
            annual_avg
		}
		portal_qry_facility_basic(where: { wdid: { _eq: $wdid } }) {
			facility_address
			wdid
			facility_name
		}
	}
    `;

    return {
        setQuery,
        REQUEST
    }
}