import React from "react";
import {FacilityCol, FacilityRow, FacilityStyled} from "./FacilityStyled";
import {Title} from "ui/base/Title";
import {Contact} from "ui/blocks/facility/Contact";
import {Nals} from "ui/blocks/facility/Nals";
import TMDL from "ui/blocks/facility/TMDL/TMDL";
import {FacilityInfoCol} from "ui/blocks/portal/FacilityInfoCol";

const FacilityView = () => {
    return (
        <FacilityStyled>
            <FacilityRow>
                <FacilityInfoCol/>
                <FacilityCol>
                    <Title size={"h5"} position="left">
                        CONTACT
                    </Title>
                    <Contact/>
                </FacilityCol>
            </FacilityRow>
            <FacilityRow>
                <FacilityCol wide>
                    <Title size={"h5"} position="left">
                        Numeric Action Levels (NALs) and Exceedance Response Action (ERA) Status
                    </Title>
                    <Nals/>
                </FacilityCol>
            </FacilityRow>
            <FacilityRow>
                <FacilityCol wide>
                    <Title size={"h5"} position="left">
                        Applicable Total Maximum Daily Load (TMDLs)
                    </Title>
                    <TMDL/>
                </FacilityCol>
            </FacilityRow>
        </FacilityStyled>
    );
};

export default FacilityView;
