import React, {ReactNode} from "react";
import {Row, WaterQualityViewStyled} from "../WaterQualityViewStyled";
import {Col} from "ui/blocks/WaterQuality/WaterQualityView/Col";
import {Title} from "ui/base/Title";

interface ColProps {
    children: ReactNode;
}

const Message = ({children}:ColProps) => {
    return (
        <WaterQualityViewStyled>
            <Row>
                <Col width={100}>
                    <Title position='left' size='h5'>
                        Water Quality Data Summary
                    </Title>
                </Col>
                <Col width={100}>
                    <p>{children}</p>
                </Col>
            </Row>
        </WaterQualityViewStyled>
    );
};

export default Message;
