import React, {useEffect, useState} from "react";
import {HomeCol, Paragraph} from 'ui/blocks/monitoring/MonitoringView/MonitoringViewStyled';
import {Col} from "ui/blocks/portal/PortalView/PortalViewStyled";
import api from "api";
import store from "ui/redux/store";
import {Title} from "ui/base/Title";

type data = {
    id: number
    notes: string
    showonhome: boolean
    wdid: string
}

const HomeNotes = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState<data[]>();
    const [wdid, setWdid] = useState(store.getState().wdid)
    store.subscribe(() => setWdid(store.getState().wdid))

    useEffect(() => {
        api.get('home_notes').then(
            (res) => {
                setData(res.data)
                setLoading(true)
            }, (error) => {
                setError(error)
                setLoading(true)
            }
        )
    }, [])

    if (!loading) return <p>Loading...</p>;
    else if (error) return <p>Error :(</p>;
    else if (data) {
        const filterData: data[] = data!.filter((v: any) => v.wdid === wdid)
        if (!!filterData.length) {
            return (
                <HomeCol>
                    <Title size="h5" position="left">
                        Notes
                    </Title>
                    {filterData.map((value) => {
                        return <Paragraph>{value.notes}</Paragraph>
                    })}
                </HomeCol>
            );
        } else return <></>
    } else return <Col><p>Loading...</p></Col>;
};

export default HomeNotes;
