import React, {useState} from "react";
import {Title} from "ui/base/Title";
import {Table} from "ui/base/Table";
import {Col} from "ui/blocks/portal/PortalView/PortalViewStyled";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {WDID} from "mock/WDID";
import store from "ui/redux/store";
import {HomeNotes} from "../HomeNotes";

const REQUEST = gql`
query MyQuery ($wdid: String!) {
  portal_qry_facility_era_report(where: {wdid: { _eq: $wdid }})  {
    wdid
    report_type
    report_due
    parameter_smarts_erarpt
  }
}
`;

type itemType = {
    report_type: string,
    report_due: string,
    parameter_smarts_erarpt: string,
    wdid: string
}

const TasksCol = () => {
    const [newWDID, setNewWDID] = useState(WDID)
    const {loading, error, data} = useQuery(REQUEST, {
        variables: {wdid: newWDID},
    });

    store.subscribe(() => {
        setNewWDID(store.getState().wdid)
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <Col>
            <Title size="h5" position="left">
                Upcoming Tasks
            </Title>
            <Table
                header={
                    <tr>
                        <th align="left">Event</th>
                        <th align="left">Parameter</th>
                        <th align="left">Due Date</th>
                    </tr>
                }
                footer={
                    <tr>
                        <th colSpan={3} align="left"><span>Reminders</span>{" "}<a href="/monitoring">Review Group
                            Leader Inspection Notes</a> (applicable to compliance group members only)
                        </th>
                    </tr>
                }
            >
                {data.portal_qry_facility_era_report.map(({
                                                              report_type,
                                                              report_due,
                                                              parameter_smarts_erarpt
                                                          }: itemType) => {
                    return <tr>
                        <td>{report_type}</td>
                        <td>{parameter_smarts_erarpt}</td>
                        <td>{report_due}</td>
                    </tr>
                })}
                <tfoot>

                </tfoot>
            </Table>
            <HomeNotes/>
        </Col>
    );
};

export default TasksCol;
