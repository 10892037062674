import React from "react";
import Layout from "ui/base/Layout/Layout";
import { MonitoringView } from "ui/blocks/monitoring/MonitoringView";

const Monitoring = () => {
  return (
    <Layout title={"Monitoring And Inspections"}>
      <MonitoringView />
    </Layout>
  );
};

export default Monitoring;
