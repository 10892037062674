import styled from "styled-components";
import { themeColor, themeFontSize } from "../../../theme";

export const ResourcesViewStyled = styled.div`
  padding: 24px 50px;

`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 32px;
  flex-wrap:wrap;
  justify-content: space-between;
    overflow-x: auto;
`;

export const Col = styled.div`
    width: calc(50% - 23px);
  @media(max-width:1024px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`;

export const WebsitesWrapper = styled.div`
  padding: 20px;
  background-color: #fefefe;
  width: 100%;
  ul {
    padding: 0;
    list-style-type: ">";
  }
  li {
    padding-left: 15px;

    a {
      color: #0e5992;
      font-size: ${themeFontSize("regular")};
      &:hover {
        color: ${themeColor("primary")};
      }
    }
  }
`;
