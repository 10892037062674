import React from "react";

export const DownloadIcon = () => {
  return (
    <svg
      version="1.1"
      id="download_icon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="612px"
      height="612px"
      viewBox="0 0 612 612"
      enableBackground="new 0 0 612 612"
    >
      <g>
        <g id="_x37__43_">
          <g>
            <path
              d="M403.939,295.749l-78.814,78.833V172.125c0-10.557-8.568-19.125-19.125-19.125c-10.557,0-19.125,8.568-19.125,19.125
				v202.457l-78.814-78.814c-7.478-7.478-19.584-7.478-27.043,0c-7.478,7.478-7.478,19.584,0,27.042l108.19,108.19
				c4.59,4.59,10.863,6.005,16.812,4.953c5.929,1.052,12.221-0.382,16.811-4.953l108.19-108.19c7.478-7.478,7.478-19.583,0-27.042
				C423.523,288.29,411.417,288.29,403.939,295.749z M306,0C137.012,0,0,136.992,0,306s137.012,306,306,306s306-137.012,306-306
				S475.008,0,306,0z M306,573.75C158.125,573.75,38.25,453.875,38.25,306C38.25,158.125,158.125,38.25,306,38.25
				c147.875,0,267.75,119.875,267.75,267.75C573.75,453.875,453.875,573.75,306,573.75z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
