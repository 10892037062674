import React, {useState} from "react";
import {
    Avatar,
    Description,
    Name,
    Info,
    UserStyled,
    NavIcon,
    IconWrapper
} from "./UserStyles";

import store from 'ui/redux/store'
import {MyAccountIcon} from 'ui/icons'


type user = {
    avatar: string,
    first_name: string,
    last_name: string,
    email: string,
    middle_name: string,
    position: string,
}

const User = () => {
    const [user , setUser] =useState<user>(store.getState().user)
    store.subscribe(() => setUser(store.getState().user))
    if (user) return (
            <UserStyled>
                {user.avatar ? (
                    <Avatar>
                        <img src={user.avatar} alt='avatar'/>
                    </Avatar>
                ) : (
                    <IconWrapper>
                        <NavIcon height={61}>
                            <MyAccountIcon/>
                        </NavIcon>
                    </IconWrapper>
                )}
                <Info>
                    <Name>
                        {user.first_name} {user.last_name}
                    </Name>
                    <Description>{user.position}</Description>
                </Info>
            </UserStyled>
        );
    else return <div></div>
};

export default User;
