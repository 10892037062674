import React, { useState } from "react";
import Layout from "ui/base/Layout/Layout";
import { PortalView } from "ui/blocks/portal/PortalView";
import store from "ui/redux/store";

const Home = () => {
  const [isLogin, setIsLogin] = useState(store.getState().logged)

  store.subscribe(() => {
    setIsLogin(store.getState().logged)
})

  return (
    <Layout title={"Quick Glance Dashboard"}>
      {
      isLogin 
      ? 
      <PortalView />
      : 
      <div>Loading...</div>
      }
    </Layout>
  );
};

export default Home;