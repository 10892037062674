import styled from "styled-components";
import {themeColor} from "ui/theme";

export const ResourcesPeoplesStyled = styled.div`
  display: flex;
  justify-content: space-around;
  overflow-x: auto;
  flex-wrap: wrap;
`;

export const PeoplesItem = styled.div`
  width:130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 15px;
  @media (max-width: 1450px) {
    width: 25%;
  }
`;

export const Avatar = styled.div`
  display: flex;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: ${themeColor("blue")};
    
  img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Name = styled.div`
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: ${themeColor("dark")};
`;

export const Position = styled.div`
  text-align: center;
  margin-top: 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${themeColor("dark")};
`;
