import { createStore } from "redux";
import {CHANGE_WDID , LOGIN, LOGOUT, SETDOCS ,SETSTEP} from "./actions/actions";

const initialState:any = {
    wdid: 'WDID',
    coordinates: {lon: 0, lat: 0},
    logged: false,
    user: {},
};

function getStep(logged:any, user:any, docs:any) {
    if(!logged) return 'not_logged';
    if(
        user.role === 'verified'
        && !(user.email)
    ) return 'verified1';
    if(user.role === 'resident') return 'gooduser';
    if(user.role === 'admin') return 'admin';
}

const reducer = function (state:any, action:any) {

    switch (action.type) {

        case CHANGE_WDID :
            return {
                ...state,
                wdid: action.wdid,
                coordinates: {
                    lon: action.lon,
                    lat: action.lat,
                }
            }
        case LOGIN:
            return {
                ...state,
                logged: true,
                user: action.user
            };
        case LOGOUT:
            return {
                ...state,
                logged: false,
            };
        case SETSTEP:
            const step = getStep(state.logged, action.user, action.docs);
            return {
                ...state,
                userVerificationStep: step
            };
        case SETDOCS:
            return {
                ...state,
                user_docs: action.docs
            };

        default:
            return state
    }
}

const store = createStore(reducer, initialState);

export default store;