import React, {useState, useEffect} from "react";
import {
    ArrowWrapper,
    DataList,
    Input,
    InputWrapper,
    Item,
    Link,
    LinkPage,
    SearchStyled,
} from "ui/blocks/Header/Search/SearchStyled";
import {ArrowIcon, SearchIcon} from "ui/icons";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {Col} from "ui/blocks/portal/PortalView/PortalViewStyled";
import {CHANGE_WDID} from "ui/redux/actions/actions";
import store from "ui/redux/store"
import api from 'api';

const REQUEST = gql`
query MyQuery {
  portal_qry_facility_basic {
    sw_lat
    sw_long
    facility_name
    wdid
  }
}
`;

interface waterTypes {
    sw_lat: string,
    sw_long: string,
}

interface arrType extends waterTypes {
    facility_name: string,
    wdid: string,
}

interface Facilities extends waterTypes {
    id: number,
    wdid: string,
    date_added: string,
    name: string
}

type facUser = {
    id: number,
    user_id: number,
    facility_id: number,
    date_added: string,
}

function fetOptions(data: arrType[], facilities: Facilities[], facUser: facUser[]) {
    let searchOptions: Facilities[] = []

    data.forEach((gsiFac) => {
        facilities.forEach((ourFac) => {
            facUser.forEach((facUserVal) => {
                if (ourFac.wdid === gsiFac.wdid && ourFac.id === facUserVal.facility_id) {
                    searchOptions.push({
                        wdid: ourFac.wdid,
                        sw_lat: gsiFac.sw_lat,
                        sw_long: gsiFac.sw_long,
                        name: gsiFac.facility_name,
                        date_added: ourFac.date_added,
                        id: ourFac.id
                    })
                }
            })
        })
    })
    return searchOptions
}

function byField(field: string) {
    return (a: any, b: any) => {
        return a[field] > b[field] ? 1 : -1
    };
}

function getWdidName(wdid: string, data: arrType[]) {
    return `${data.find((v) => v.wdid === wdid)?.facility_name} ${data.find((v) => v.wdid === wdid)?.wdid}`
}

export const Search = () => {
    const {loading, error, data} = useQuery(REQUEST);
    const [isLoad, setIsLoad] = useState(false)
    const [value, setValue] = useState("");
    const [open, setOpen] = useState(false);
    const [facilities, setFacilities] = useState<Facilities[]>([])
    const [facUser, setFacUser] = useState<facUser[]>()
    const [err, setError] = useState(null)
    const [user, setUser] = useState<facUser>(store.getState().user)
    const [wdid, setWdid] = useState(store.getState().wdid)

    store.subscribe(() => {
        setUser(store.getState().user)
        setWdid(store.getState().wdid)
    })

    useEffect(() => {
        if (user && user.id) {
            api.getSome('facilities2users', `?user_id=eq.${user.id}`)
                .then((res: any) => {
                    setFacUser(res.data)
                    api.get('facilities').then(
                        (res) => {
                            setFacilities(res.data)
                        }
                    )
                    setIsLoad(true)
                }).then((error: any) => {
                setError(error)
            })
        }
    }, [user])

    if (loading && !isLoad && !data && !facilities && !facUser) {
        return <p>Loading...</p>;
    } else if (error || err) {
        return <p>Error :(</p>;
    } else if (data && facilities && facUser) {
        let searchOptions = fetOptions(data.portal_qry_facility_basic, facilities, facUser)
        let wdidName = getWdidName(wdid, data.portal_qry_facility_basic)
        if (store.getState().wdid === 'WDID') {
            const wdidOp = searchOptions[0]
            if (wdidOp) {
                store.dispatch({
                    type: CHANGE_WDID,
                    wdid: wdidOp.wdid,
                    lon: +wdidOp.sw_long,
                    lat: +wdidOp.sw_lat
                });
            }
        }
        searchOptions.length > 0 && searchOptions.sort(byField('name'))
        return (
            <SearchStyled>
                <SearchIcon/>
                <InputWrapper isOpen={open}>
                    <Input
                        placeholder={wdidName}
                        list="input-search"
                        value={value}
                        onFocus={() => {
                            setValue('')
                            setOpen(true);
                        }}
                        onClick={() => {
                            if (window.innerWidth < 1024) {
                                setValue('')
                                setOpen(true);
                            }
                        }}
                        onBlur={() => {
                            setTimeout(() => {
                                setOpen(false)
                            }, 400);
                        }}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                    />
                    <ArrowWrapper onClick={() => {
                        setOpen((state) => !state)
                        setValue('')
                    }}>
                        <ArrowIcon/>
                    </ArrowWrapper>
                    <DataList active={open}>
                        {searchOptions?.map((val) => {
                            if (val && val.name || val.wdid) {

                                if (val.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || val.wdid.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                                    return (
                                        <Item
                                            key={val.wdid}
                                            onClick={() => {
                                                setValue(val.name)
                                                setOpen(!open)
                                                store.dispatch({
                                                    type: CHANGE_WDID,
                                                    wdid: val.wdid,
                                                    lon: +val.sw_long,
                                                    lat: +val.sw_lat
                                                });
                                            }}
                                        >
                                            <Link>
                                                {val.name}
                                                <LinkPage> {val.wdid}</LinkPage>
                                            </Link>
                                        </Item>
                                    )

                                } else return ''
                            }
                        })}
                    </DataList>
                </InputWrapper>
            </SearchStyled>
        );
    } else return <Col><p>Loading...</p></Col>;
};
