import React from "react";
import Layout from "ui/base/Layout/Layout";
import { ResourcesView } from "ui/blocks/resources/ResourcesView";

const Resources = () => {
  return (
    <Layout title={"Resources"}>
      <ResourcesView />
    </Layout>
  );
};

export default Resources;
