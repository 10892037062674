import React, {useEffect, useState} from "react";
import {Table} from "ui/base/Table";
import api from 'api';

const FacilityInspectionTable = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        api.get('resources')
            .then(res => res)
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error.error);
                }
            )
    }, [])
    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        const form = items.filter((arr: any) => arr.r_type === "form")

        return (

                <Table
                    header={
                        <tr>
                            <th>Form Type</th>
                            <th>Frequency</th>
                            <th>Download</th>
                        </tr>
                    }
                >
                    {form.map((item: any) => (
                        <tr>
                            <td align="center">{item.title}</td>
                            <td align="center">{item.frequency}</td>
                            <td align="center">
                                <a href={item.link}>Link</a>
                            </td>
                        </tr>
                    ))}
                </Table>
        );
    }
};

export default FacilityInspectionTable;
