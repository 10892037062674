import React from "react";
import Layout from "../ui/base/Layout/Layout";

import { WaterQualityView } from "ui/blocks/WaterQuality/WaterQualityView";

const WaterQuality = () => {
  return (
    <Layout title={"Water Quality Data"}>
      <WaterQualityView />
    </Layout>
  );

};

export default WaterQuality;
