import React, { ReactNode } from "react";
import { Body, BodyWrapper, Container, LayoutStyled } from "./LayoutStyled";
import { Sidebar } from "ui/blocks/Sidebar";
import { Header } from "ui/blocks/Header";
import { useMediaQuery } from "react-responsive";

interface LayoutProps {
  title: string;
  children: ReactNode;
}

const Layout = ({ title, children }: LayoutProps) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  return (
    <LayoutStyled>
      <Container>
        <Sidebar />
        <BodyWrapper>
          {isDesktop && <Header title={title} />}
          <Body>{children}</Body>
        </BodyWrapper>
      </Container>
    </LayoutStyled>
  );
};

export default Layout;
