import React, {useEffect, useState} from "react";
import "global/globals.css";
import "global/fonts.css";
import {AppThemeProvider} from "./ui/theme/AppThemeProvider";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {routes} from "./routes";
import {client} from "./data";
import {ApolloProvider} from "@apollo/client";


import {bindActionCreators} from 'redux';
import {connect, Provider} from 'react-redux';
import jwt from "jwt-decode";
import queryString from 'query-string';
import * as LoginActions from 'ui/redux/actions/creators';
import * as LiveTickerActions from "ui/redux/actions/creators";
import * as CurrencyActions from "ui/redux/actions/creators";
import api from "api";
import helpers from "./config/helpers";

import store from "./ui/redux/store";
import {LOGIN} from "./ui/redux/actions/actions";


function App() {

    function setUser(this: any) {

        let token = window.localStorage.getItem('accessToken');
        if (token) {
            const decodedToken: any = jwt(token);
            api.getOne('users', decodedToken.id, '')
                .then(function (this: any, user: any) {
                    if (!user.error.occur && user.data.length && user.data[0].id) {

                        const partnerCode = window.localStorage.getItem('partnerCode');
                        if (partnerCode) {
                            api.create('rpc/partners_assign', {user_id: user.data[0].id, partner_code: partnerCode})
                                .then(function (data) {
                                    window.localStorage.removeItem('partnerCode');
                                });
                        }
                        let loggedUser: any = {};
                        Object.assign(loggedUser, user.data[0]);
                        store.dispatch({
                            type: LOGIN,
                            logged: true,
                            user: loggedUser,
                        })
                    } else {
                        helpers.callSignout();
                    }
                }.bind(this));
        }
    }

    const parsed: any = queryString.parse(window.location.search);

    if (parsed.p !== undefined) {
        window.localStorage.setItem('partnerCode', parsed.p);
        window.history.pushState({}, document.title, "/");
    }
    setUser();

    useEffect(() => {
        setTimeout(() => {
            if (!localStorage.getItem('accessToken')) {
                helpers.callSignin()
            }
        }, 500)
    }, [])
    return (
        <ApolloProvider client={client}>
            <Provider store={store}>
                <AppThemeProvider>
                    <Router>
                        <Routes>
                            {routes.map((route, index) => (
                                <Route key={index} path={route.path} element={route.component}/>
                            ))}
                        </Routes>
                    </Router>
                </AppThemeProvider>
            </Provider>
        </ApolloProvider>
    );
}

const mapStateToProps = (state: any) => ({
    logged: state.logged.logged,
    userVerifStep: state.logged.userVerificationStep,
});

const mapDispatchToProps = (dispatch: any) => ({
    actions: bindActionCreators(LoginActions, dispatch),
    actionsCurrency: bindActionCreators(CurrencyActions, dispatch),
    actionsLiveTicker: bindActionCreators(LiveTickerActions, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(App);

