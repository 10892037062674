import styled from "styled-components";

export const WeatherWrapper = styled.div`
    overflow-x: auto;
  background-color: #fff;
  padding: 16px;
`;
export const WeatherRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;
export const WeatherCol = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 10px;
  background-color: ${({active}) => active && "#f6f6f6"};
`;
export const WeatherDate = styled.div`
  font-size: 12px;
  color: #202124;
  margin-bottom: 4px;
`;
export const Day = styled.div`
  font-size: 14px;
  color: #202124;
`;
export const Icon = styled.img`
  width: 32px;
`;
export const Temp = styled.div`
  margin-top: 11px;
  font-size: 14px;
  color: #202124;
  p {
    text-align: center;
    
    img {
        max-height: 14px;
        width: auto
    }
  }
  span {
    margin-left: 4px;
    color: #9a9a9a;
  }
`;
