import styled from "styled-components";
import { ColorName, Size, themeColor } from "../../theme";

interface ButtonStyledProps {
  background?: ColorName;
  color?: ColorName;
  size?: Size;
  transparent?: boolean;
  center?: boolean;
  round?: boolean;
}

export const ButtonStyled = styled.a<ButtonStyledProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.dark};
  font-size: ${({ theme, size }) =>
    size === "small"
      ? theme.fonts.sizes.small
      : size === "medium"
      ? theme.fonts.sizes.regular
      : theme.fonts.sizes.big};
  border-radius: ${({ round }) => (round ? "12px" : "0")};
  background: ${({ theme, background, transparent }) =>
    background && !transparent
      ? theme.colors[background]
      : transparent
      ? "transparent"
      : theme.colors.primary};
  border: 1px solid transparent;
  ${({ transparent }) =>
    transparent && `border-color: ${themeColor("primary")}`};
  font-weight: 500;
  padding: ${({ size }) =>
    size === "small"
      ? "8px 12px"
      : size === "medium"
      ? "8px 24px"
      : "8px 32px"};
  ${({ center }) => center && "margin: 0 auto;"};
`;
