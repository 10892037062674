import React from "react";
import {SidebarStyled} from "./SidebarStyled";
import {Navigation} from "./Navigation";

const Sidebar = () => {
    return (
        <SidebarStyled>
            <Navigation/>
        </SidebarStyled>
    );
};

export default Sidebar;
