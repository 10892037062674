import { ReactNode } from "react";
import {
  Profile,
  Home,
  Info,
  Account,
  WaterQuality,
  Monitoring,
  Resources,
  ContactUs,
} from "./pages";

import RedirectPage from 'pages/RedirectPage'

export const routesPath = {
  profile: "/profile",
  home: "/",
  info: "/info",
  monitoring: "/monitoring",
  waterQuality: "/water-quality",
  resources: "/resources",
  account: "/profile",
  contactUs: "/contact-us",
  redirect: "/redirect",
  redirect_to_login: "/redirect_to_login",
  logout: '/logout'
};

type RouteType = {
  path: string;
  component: ReactNode;
};

export const routes: RouteType[] = [
  {
    path: routesPath.redirect,
    component: <RedirectPage props={'/'} />
  },
  {
    path: routesPath.profile,
    component: <Profile />,
  },
  {
    path: routesPath.home,
    component: <Home />,
  },
  {
    path: routesPath.info,
    component: <Info />,
  },
  {
    path: routesPath.monitoring,
    component: <Monitoring />,
  },
  {
    path: routesPath.account,
    component: <Account />,
  },
  {
    path: routesPath.waterQuality,
    component: <WaterQuality />,
  },
  {
    path: routesPath.resources,
    component: <Resources />,
  },
  {
    path: routesPath.contactUs,
    component: <ContactUs />,
  },
];
