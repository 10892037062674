import styled from "styled-components";
import {themeColor, themeFontSize} from "ui/theme";
import {ButtonStyled} from "ui/base/Button/ButtonStyled";

export const UserSummaryStyled = styled.div`
    background-color: #fefefe;
    margin-bottom: 34px;
`;

export const UserTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableHead = styled.div`
  display: flex;
  background-color: ${themeColor("lightBlue")};
  color: ${themeColor("blue")};
  font-size: 20px;
  font-weight: 500;
  padding: 16px 14px 16px 24px;
`;

export const Content = styled.div`
  display: flex;
  padding: 25px;
      @media (max-width:1024px) {

    flex-wrap:wrap;
  }
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px 0 13px 24px;
`;
export const ContentTitle = styled.div`
    display: flex;
    font-family: Rajdhani;
    font-size: ${themeFontSize('biggest')};
    font-weight: 600;
    color: #191919;
`;
export const TableRow = styled.div`
  display: flex;
  // justify-content: space-between;
    @media (max-width:1024px) {

    flex-wrap:wrap;
  }
`;
export const TableCol = styled.div`
  display: flex;
  padding: 15px 25px;
  border: solid 1px ${themeColor('borderGrey')};
  width: 64%;
  min-width: fit-content;
  
  &:first-child {
    width: 36%;
  };
  @media (max-width:1024px) {
    flex-direction:column;
    flex-wrap:wrap;
  }
`;
export const ColStart = styled.div`
    display: flex;
    font-family: Rajdhani;
    font-size: 14px;
    font-weight: 600;
    color: ${themeColor('lightGrey')};
    width: 40%;
    min-width: fit-content;
`;

export const ColEnd = styled.div`
    display: flex;
    Text: Contact;
    font-family: Rajdhani;
    font-size: 14px;
    font-weight: 600;
    color: ${themeColor('darkGrey')};
    width: 60%;
    min-width: fit-content;
`;

export const ActionBtn = styled(ButtonStyled)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: Rajdhani;
`;

export const Picture = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${themeColor("blue")};
  width: 100px;
  height: 100px;
  
  font-family: Rajdhani;
  font-size: ${themeFontSize('h1')};
  font-weight: 500;
  color: #fefefe;
  
  img {
      width:100%;
      height: 100%;
      object-fit: cover;
  }
`;
export const NavIcon = styled.div<{ height: number }>`
  height: ${({height}) => `${height / 10}vh`};
  margin-bottom: 1.2vh;
  svg {
    height: 100%;
    fill: white;
    width: auto;
  }
`;

