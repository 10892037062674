import React, {useState} from "react";
import {Table} from "ui/base/Table";
import {FacilityInfoStyled} from "./FacilityInfoStyled";
import gql from 'graphql-tag';
import {useQuery} from "@apollo/client";
import {WDID} from "mock/WDID";
import store from "ui/redux/store";

const REQUEST = gql`
query MyQuery ($wdid: String!) {
  portal_qry_facility_basic(where: {wdid: { _eq: $wdid }}) {
    facility_name
    wdid
    facility_address
    sic
    group_name
    receiving_water
  }
}
`;

type itemType = {
    facility_name: string,
    wdid: string,
    facility_address: string,
    sic: string,
    group_name: string,
    receiving_water: string
}

const FacilityInfo = () => {
    const [newWDID, setNewWDID] = useState(WDID)
    const {loading, error, data} = useQuery(REQUEST, {
        variables: {wdid: newWDID},
    });

    store.subscribe(() => {
        setNewWDID(store.getState().wdid)
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <FacilityInfoStyled>
            {
                data.portal_qry_facility_basic.map(({
                                                        facility_name,
                                                        wdid,
                                                        facility_address,
                                                        sic,
                                                        group_name,
                                                        receiving_water
                                                    }: itemType) => <Table
                                                    key={wdid}
                        width={155}
                        header={
                            <tr>
                                <th align="left">Facility Name:</th>
                                <th align="left">
                                    <span>{facility_name}</span>
                                </th>
                            </tr>
                        }
                    >
                        <tr>
                            <td>
                                <strong>WDID:</strong>
                            </td>
                            <td>{wdid}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Address:</strong>
                            </td>
                            <td>{facility_address}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>SIC Code:</strong>
                            </td>
                            <td>{sic}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Compliance Group:</strong>
                            </td>
                            <td>{group_name}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Receiving Water:</strong>
                            </td>
                            <td>{receiving_water}</td>
                        </tr>
                    </Table>
                )
            }
        </FacilityInfoStyled>
    );
};

export default FacilityInfo;
