import React from "react";

export const MonitoringIcon = () => {
  return (
    <svg
      id="monitoring_icon"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="XMLID_2147_">
        <g id="XMLID_529_">
          <path
            id="XMLID_1481_"
            d="m462 20h-161c-5.522 0-10 4.478-10 10s4.478 10 10 10h161c16.542 0 30 13.458 30 30v229.233h-196.016c16.832-20.747 26.935-47.164 26.935-75.899 0-66.535-54.131-120.666-120.666-120.666s-120.666 54.131-120.666 120.666c0 19.859 4.826 38.612 13.361 55.152l-21.514 21.514h-53.434v-230c0-16.542 13.458-30 30-30h161c5.523 0 10-4.478 10-10s-4.477-10-10-10h-161c-27.57 0-50 22.43-50 50v272c0 8.895 2.414 17.652 6.873 25.277-10.294 14.396-8.99 34.589 3.928 47.509 6.966 6.965 16.226 10.801 26.076 10.801 9.851 0 19.111-3.836 26.077-10.801l22.786-22.786h96.716l-9.322 36h-11.134c-17.645 0-32 14.355-32 32s14.355 32 32 32h188c17.645 0 32-14.355 32-32s-14.355-32-32-32h-11.134l-9.322-36h132.456c27.57 0 50-22.43 50-50v-272c0-27.57-22.43-50-50-50zm-259.747 102.668c55.508 0 100.666 45.158 100.666 100.666s-45.158 100.666-100.666 100.666-100.666-45.158-100.666-100.666 45.158-100.666 100.666-100.666zm-148.819 197.332-31.804 31.804c-1.075-3.113-1.63-6.408-1.63-9.804v-22zm-4.622 80.644c-6.578 6.581-17.286 6.581-23.868.001-6.58-6.581-6.58-17.289 0-23.869l80.927-80.927c6.805 9.023 14.845 17.063 23.868 23.868zm313.188 59.356c0 6.617-5.383 12-12 12h-188c-6.617 0-12-5.383-12-12s5.383-12 12-12h188c6.617 0 12 5.383 12 12zm-43.794-32h-124.412l9.321-36h105.77zm143.794-56h-356.26l41.361-41.361c16.54 8.536 35.292 13.361 55.152 13.361 27.479 0 52.837-9.24 73.145-24.767h216.602v22.767c0 16.542-13.458 30-30 30z"
          />
          <path
            id="XMLID_1509_"
            d="m202.253 275.667c33.146 0 63.276-18.28 78.633-47.706 1.513-2.899 1.513-6.355 0-9.254-15.356-29.426-45.486-47.706-78.633-47.706s-63.277 18.28-78.634 47.706c-1.513 2.899-1.513 6.354 0 9.254 15.357 29.426 45.487 47.706 78.634 47.706zm0-68.667c9.007 0 16.334 7.327 16.334 16.334s-7.327 16.334-16.334 16.334-16.334-7.327-16.334-16.334 7.327-16.334 16.334-16.334zm58.259 16.334c-8.448 13.556-21.168 23.542-35.892 28.602 8.493-6.657 13.967-16.999 13.967-28.602s-5.474-21.945-13.967-28.602c14.724 5.06 27.444 15.046 35.892 28.602zm-80.626-28.602c-8.494 6.657-13.967 16.999-13.967 28.602 0 11.602 5.474 21.945 13.967 28.602-14.725-5.059-27.445-15.046-35.893-28.602 8.449-13.556 21.169-23.542 35.893-28.602z"
          />
          <path
            id="XMLID_1518_"
            d="m347.396 143.157c0 5.523 4.477 10 10 10h100.604c5.522 0 10-4.477 10-10 0-5.522-4.478-10-10-10h-100.604c-5.523 0-10 4.478-10 10z"
          />
          <path
            id="XMLID_1519_"
            d="m458 105.157c5.522 0 10-4.478 10-10s-4.478-10-10-10h-57.891c-5.522 0-10 4.478-10 10s4.478 10 10 10z"
          />
          <path
            id="XMLID_1520_"
            d="m356.55 85.16c-2.63 0-5.21 1.069-7.07 2.93-1.859 1.86-2.92 4.439-2.92 7.07 0 2.63 1.06 5.21 2.92 7.069 1.87 1.86 4.45 2.931 7.07 2.931 2.64 0 5.21-1.07 7.07-2.931 1.87-1.859 2.94-4.439 2.94-7.069 0-2.631-1.069-5.21-2.94-7.07-1.85-1.861-4.43-2.93-7.07-2.93z"
          />
          <path
            id="XMLID_1521_"
            d="m465.069 198.23c1.86-1.859 2.931-4.44 2.931-7.069 0-2.631-1.07-5.21-2.931-7.07-1.859-1.86-4.439-2.93-7.069-2.93-2.641 0-5.21 1.069-7.07 2.93-1.86 1.86-2.93 4.439-2.93 7.07 0 2.63 1.069 5.199 2.93 7.069 1.86 1.86 4.44 2.931 7.07 2.931s5.21-1.071 7.069-2.931z"
          />
          <path
            id="XMLID_1522_"
            d="m407.7 201.16c2.63 0 5.21-1.07 7.069-2.931 1.86-1.859 2.931-4.44 2.931-7.069 0-2.631-1.07-5.21-2.931-7.07-1.859-1.86-4.439-2.93-7.069-2.93-2.641 0-5.221 1.069-7.07 2.93-1.86 1.86-2.93 4.43-2.93 7.07 0 2.63 1.069 5.21 2.93 7.069 1.849 1.861 4.43 2.931 7.07 2.931z"
          />
          <path
            id="XMLID_1523_"
            d="m364.47 198.23c1.86-1.859 2.92-4.44 2.92-7.069 0-2.641-1.059-5.21-2.92-7.07-1.86-1.86-4.439-2.93-7.07-2.93-2.64 0-5.22 1.069-7.08 2.93-1.859 1.86-2.92 4.43-2.92 7.07 0 2.63 1.061 5.21 2.92 7.069 1.87 1.86 4.44 2.931 7.08 2.931 2.63-.001 5.209-1.071 7.07-2.931z"
          />
          <path
            id="XMLID_1524_"
            d="m357.396 249.157h32.104c5.522 0 10-4.477 10-10 0-5.522-4.478-10-10-10h-32.104c-5.523 0-10 4.478-10 10 0 5.523 4.477 10 10 10z"
          />
          <path
            id="XMLID_1525_"
            d="m423.5 239.157c0 5.523 4.478 10 10 10h32.94c5.522 0 10-4.477 10-10 0-5.522-4.478-10-10-10h-32.94c-5.522 0-10 4.478-10 10z"
          />
          <path
            id="XMLID_1526_"
            d="m256 40c2.63 0 5.21-1.07 7.069-2.931 1.861-1.859 2.931-4.439 2.931-7.069s-1.07-5.21-2.931-7.07c-1.859-1.861-4.439-2.93-7.069-2.93s-5.21 1.069-7.07 2.93c-1.86 1.86-2.93 4.44-2.93 7.07s1.069 5.21 2.93 7.069c1.86 1.861 4.44 2.931 7.07 2.931z"
          />
        </g>
      </g>
    </svg>
  );
};
