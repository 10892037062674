import React, {useEffect, useState} from "react";
import {
    Day,
    Icon,
    Temp,
    WeatherCol,
    WeatherRow,
    WeatherWrapper,
    WeatherDate,
} from "./WeatherStyled";
import store from "ui/redux/store"

type weatherType = {
    id: number;
    main: string;
    description: string;
    icon: string;
};

type dailyType = {
    dt: number;
    sunrise: number;
    sunset: number;
    moonrise: number;
    moonset: number;
    moon_phase: number;
    temp: {
        day: number;
        min: number;
        max: number;
        night: number;
        eve: number;
        morn: number;
    };
    feels_like: {
        day: number;
        night: number;
        eve: number;
        morn: number;
    };
    pressure: number;
    humidity: number;
    dew_point: number;
    wind_speed: number;
    wind_deg: number;
    weather: weatherType[];
    clouds: number;
    pop: number;
    rain: number;
    uvi: number;
};

export const Weather = () => {
        const [isLoaded, setIsLoaded] = useState(false);
        const [error, setEroor] = useState(null);
        const [weather, setWeather] = useState<dailyType[] | null>(null);
        const [coordinates, setCoordinates] = useState({lon: 0, lat: 0});
        const weatherApi: any = process.env.REACT_APP_WEATHER_API;

        store.subscribe(() => {
            setCoordinates(store.getState().coordinates)
            setIsLoaded(false)
        })

        useEffect(() => {
            setCoordinates({
                lon: store.getState().coordinates.lon,
                lat: store.getState().coordinates.lat,
            });
        }, []);

    useEffect(() => {
        const getWeather = async () => {
            const response = await fetch(
                weatherApi.replace('__lat', coordinates.lat).replace("__lon", coordinates.lon)
            );
            return await response.json();
        };
        if (!isLoaded) {
            setIsLoaded(true);
            getWeather().then((data) => setWeather(data.daily.slice(0, -1)));
        }
    });


        if (!isLoaded) {
            return <div>Loading...</div>;
        }  else if (coordinates) {
            return (
                <WeatherWrapper>
                    <WeatherRow>
                        {weather?.map((day, index) => {
                            return (
                                <WeatherCol key={index} active={index === 0}>
                                    <WeatherDate>
                                        {new Date(day.dt * 1000).toLocaleDateString("en")}
                                    </WeatherDate>
                                    <Day>
                                        {new Date(day.dt * 1000).toLocaleDateString("en", {
                                            weekday: "long",
                                        })}
                                    </Day>
                                    <Icon
                                        src={`https://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`}
                                        alt={day.weather[0].description}
                                    />
                                    <Temp>
                                        <p>
                                            {(day.temp.day * 9 / 5 + 32).toFixed()}°
                                            <span>{(day.temp.night * 9 / 5 + 32).toFixed()}°</span>
                                        </p>
                                        <p><img src="https://openweathermap.org/img/wn/10d@2x.png"
                                                alt=""/>{(day.pop * 100).toFixed()}%</p>
                                    </Temp>
                                </WeatherCol>
                            )
                        })}
                    </WeatherRow>
                </WeatherWrapper>
            );
        } else return <div>Something wrong...</div>
    }
;
