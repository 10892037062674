import React, {useEffect, useState} from "react";
import {Table} from "ui/base/Table";
import api from "api";
import store from "ui/redux/store";

type data = {
    id: number,
    facilities: facilities,
    resources: resources
}

type facilities = {
    name: string,
    wdid: string
}

type resources = {
    link: string | null,
    r_type: string,
    showonmain: boolean,
    title: string | null,
}

const DovumentsForms = () => {
    const [data, setData] = useState<data[]>();
    const [wdid, setWdid] = useState(store.getState().wdid)
    store.subscribe(() => setWdid(store.getState().wdid))

    useEffect(() => {
        api.get(`facilities2resources?select=id,resources!inner(r_type,title,link,showonmain),facilities!inner(wdid,name)&and(resources.r_type.eq*facility_doc*,resources.r_type.eq*form*)&facilities.wdid=like.*${wdid}*&resources.showonmain.eq*${true}*`).then(
            (res) => {
                setData(res.data)
            }
        )
    }, [wdid])

    return (
        <Table widthCol={33.33} isDownload={true}>
            <tr>
                {data?.map(({resources}) => resources.showonmain ?
                    <td>
                        <a href={resources.link ? resources.link : ''}>{resources.title}</a>
                    </td>
                    : null
                )}
            </tr>

        </Table>
    );

};
export default DovumentsForms;
