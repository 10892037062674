import React from "react";
import Layout from "ui/base/Layout/Layout";

const Account = () => {
  return (
    <Layout title={""}>
      <div>Account</div>
    </Layout>
  );
};

export default Account;
