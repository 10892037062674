import styled from "styled-components";
import { themeColor } from "ui/theme";

export const BannerStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 80px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${themeColor("blue")};
  color: #fff;
  border: 1px solid #c2c2c2;
  font-size: 24px;
`;

export const Text = styled.p`
  display: flex;
  flex-direction: column;
  max-width: 530px;
  width: 100%;
  margin-bottom: 40px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

export const Button = styled.a`
  display: block;
  font-weight: 700;
  display: flex;
  font-size: 16px;
  text-transform: uppercase;
  height: 46px;
  width: auto;
  border: 1px solid #c2c2c2;
  text-align: center;
  padding: 5px 27px;
  background: #eb7c32;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;