import React, {useState} from "react";
import {Table} from "ui/base/Table";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {WDID} from "mock/WDID";
import store from "ui/redux/store";

const REQUEST = gql`
query MyQuery ($wdid: String!) {
  portal_qry_facility_contact_activeonly(where: {wdid: { _eq: $wdid }}) {
    contact_name
    email
    phone
    portal_id
    role
    wdid
  }
}
`;

type itemType = {
    contact_name: string,
    email: string,
    phone: string,
    portal_id: string,
    role: string,
    wdid: string,
}

const Contact = () => {
    const [newWDID, setNewWDID] = useState(WDID)
    const {loading, error, data} = useQuery(REQUEST, {
        variables: {wdid: newWDID},
    });
    store.subscribe(() => {
        setNewWDID(store.getState().wdid)
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    return (
        <Table
            width={190}
            header={
                <tr>
                    <th align="left">Name</th>
                    <th align="left" style={{minWidth: "150px"}}>
                        Role
                    </th>
                    <th align="left">Email</th>
                    <th align="left">Phone Number</th>
                </tr>
            }
        >
            {
                data.portal_qry_facility_contact_activeonly.map(({
                                                                     portal_id,
                                                                     contact_name,
                                                                     role,
                                                                     email,
                                                                     phone
                                                                 }: itemType) => (
                    <tr key={portal_id}>
                        <td>
                            {contact_name}
                        </td>
                        <td>
                            {role}
                        </td>
                        <td>
                            {email}
                        </td>
                        <td className="nowrap">
                            {phone}
                        </td>
                    </tr>
                ))
            }
        </Table>
    );
};

export default Contact;
