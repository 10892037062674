import styled from "styled-components";
import {ButtonStyled} from "ui/base/Button/ButtonStyled";
import {themeColor, themeFontSize} from "ui/theme";

export const UserStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 15px 20px 25px;
  @media (max-width: 1024px) {
    border: none;
    padding: 0;
  }
`;
export const Avatar = styled.div`
  position: relative;
  overflow: hidden;
  width: 97px;
  height: 97px;
  min-width: 97px;
  min-height: 97px;
  border-radius: 50%;
  
  img {
    object-fit: cover;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${themeFontSize("regular")};
  margin-left: 27px;
  line-height: 1.2;
`;

export const Name = styled.div`
  color: ${themeColor("white")};
  font-weight: 700;
`;

export const Description = styled.div`
  color: #8ebee3;
  margin-top: 7px;
`;

export const UpdateBtn = styled(ButtonStyled)`
  margin-top: 16px;
  height: 27px;
`;
export const NavIcon = styled.div<{ height: number }>`
  height: ${({height}) => `${height / 10}vh`};
  margin-bottom: 1.2vh;
  svg {
    height: 100%;
    fill: white;
    width: auto;
  }
`;
export const IconWrapper = styled.div`
      width: 6em;
      height: 6em;
      border: 2px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
`