import styled from "styled-components";
import {themeColor} from "ui/theme";

export const SidebarStyled = styled.div`
  background-color: ${themeColor("blue")};
  color: ${themeColor("white")};
  max-width: 310px;
  min-width: 310px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 1024px) {
    max-width: 100%;
    min-width: auto;
    overflow: unset;
    height: 120px;
  }
`;
