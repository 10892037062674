import React from "react";
import Layout from "ui/base/Layout/Layout";
import { FacilityView } from "ui/blocks/facility/FacilityView";

const Info = () => {
  return (
    <Layout title="Facility Info">
      <FacilityView />
    </Layout>
  );
};

export default Info;
