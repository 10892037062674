import styled from "styled-components";
import { themeColor } from "../../theme";

export const LayoutStyled = styled.div`
  background-color: ${themeColor("darkBlue")};
  height: 100vh;
`;

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  height: 100%;
  overflow-x: hidden;
  align-items: stretch;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
      overflow-x: auto;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
