import React from "react";
import {Title} from "ui/base/Title";
import {
    Col,
    PortalViewStyled,
    Row, Text,
} from "./PortalViewStyled";
import {FacilityInfoCol} from "ui/blocks/portal/FacilityInfoCol";
import {StormwaterMonitoringCol} from "ui/blocks/portal/StormwaterMonitoringCol/";
import {DocumentsForms} from "ui/blocks/portal/DovumentsForms/";
import {EraStatusCol} from "ui/blocks/portal/EraStatusCol";
import {TasksCol} from "ui/blocks/portal/TasksCol";
import {Weather} from "ui/blocks/portal/Weather";


const PortalView = () => {
    return (
        <PortalViewStyled>
            <Row>
                <FacilityInfoCol/>
                <TasksCol/>
            </Row>
            <Row>
                <EraStatusCol/>
                <StormwaterMonitoringCol background={'#fefefe'}/>
            </Row>
            <Row>
                <Col>
                    <Title size="h5" position="left">
                        Weather
                        <Text>
                            (% = &quot;precipitation probability&quot;)
                        </Text>
                    </Title>
                    <Weather/>
                </Col>
                <Col>
                    <Title size="h5" position="left">
                        Documents/Forms

                    </Title>
                    <DocumentsForms/>
                </Col>
            </Row>
        </PortalViewStyled>
    );

};

export default PortalView;
