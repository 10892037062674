import React, {useState} from "react";
import {
    Content,
    ContentTitle,
    ContentBody,
    Picture,
    TableHead,
    UserSummaryStyled,
    UserTable,
    TableCol,
    TableRow,
    ColEnd,
    ColStart,
    NavIcon
} from "./UserSummaryStyled";
import store from "ui/redux/store";
import {MyAccountIcon} from 'ui/icons'

type user = {
	avatar: string,
	first_name: string,
	last_name: string,
	email: string,
	middle_name: string,
	position: string,
}

const UserSummary = () => {
	const [user , setUser] =useState<user>(store.getState().user)
	store.subscribe(() => setUser(store.getState().user))

	if (user) return (
					<UserSummaryStyled>
						<UserTable>
							<TableHead>User Summary</TableHead>
							<Content>
								<Picture>
									{user.avatar ? (
										<img
											src={user.avatar}
											alt={user.first_name ? user.first_name : "user photo"}
										/>
									) : (
										<NavIcon height={61}>
											<MyAccountIcon />
										</NavIcon>
									)}
								</Picture>
								<ContentBody>
									<ContentTitle>
										{user.first_name} {user.last_name}
									</ContentTitle>
								</ContentBody>
							</Content>
							<TableRow>
								<TableCol>
									<ColStart>First name</ColStart>
									<ColEnd>
										<strong>{user.first_name}</strong>
									</ColEnd>
								</TableCol>
								<TableCol>
									<ColStart>User name</ColStart>
									<ColEnd>
										<strong>{user.email}</strong>
									</ColEnd>
								</TableCol>
							</TableRow>
							<TableRow>
								<TableCol>
									<ColStart>Last name</ColStart>
									<ColEnd>
										<strong>{user.last_name}</strong>
									</ColEnd>
								</TableCol>
								<TableCol>
									<ColStart>Middle name</ColStart>
									<ColEnd>
										<strong>{user.middle_name}</strong>
									</ColEnd>
								</TableCol>
							</TableRow>
							<TableRow>
								<TableCol>
									<ColStart>Position</ColStart>
									<ColEnd>
										<strong>{user.position}</strong>
									</ColEnd>
								</TableCol>
								<TableCol>
									<ColStart>Email</ColStart>
									<ColEnd>
										<strong>{user.email}</strong>
									</ColEnd>
								</TableCol>
							</TableRow>
						</UserTable>
					</UserSummaryStyled>
				);
	else return <div></div>
};

export default UserSummary;
