import React, {useEffect, useState} from "react";
import {Title} from "ui/base/Title";
import {Table} from "ui/base/Table";
import {MonitoringCol, Paragraph, Text} from 'ui/blocks/monitoring/MonitoringView/MonitoringViewStyled';
import {Col} from "ui/blocks/portal/PortalView/PortalViewStyled";
import api from "api";
import store from "ui/redux/store";

type data = {
    date_added: string,
    id: number,
    inspection_date: string,
    link: string,
    reporting_year: string,
    wdid: string,
}

const LeaderInspection = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState<data[]>();
    const [wdid, setWdid] = useState(store.getState().wdid)
    store.subscribe(() => setWdid(store.getState().wdid))

    useEffect(() => {
        api.get('group_leader_inspection').then(
            (res) => {
                setData(res.data)
                setLoading(true)
            }, (error) => {
                setError(error)
                setLoading(true)
            }
        )
    }, [])

    if (!loading) return <p>Loading...</p>;
    else if (error) return <p>Error :(</p>;
    else if (data) {

        const filterData = data.filter((v: any) => v.wdid === wdid)

        return (
            <MonitoringCol>
                <Title position="left" uppercase weight="600" size="h5">
                    Group Leader Inspection
                </Title>
                <Text>
                    (Applicable to facility enrolled in a compliance group only. GSI as the compliance group leader
                    completes the annual group leader inspection prior to the end of each reporting year and inspection
                    summary is be displayed below. )
                </Text>
                <Table
                    header={
                        <tr>
                            <th>Reporting Year</th>
                            <th>Inspection Date</th>
                            <th>Group Leader Inspection Report</th>
                        </tr>
                    }
                >
                    {filterData.map(({reporting_year, inspection_date, link}: data) => (
                        <tr>
                            <td align="center">
                                <strong>{reporting_year}</strong>
                            </td>
                            <td align="center">
                                <strong>{inspection_date}</strong>
                            </td>
                            <td align="center">
                                <a href={link}>Link</a>
                            </td>
                        </tr>
                    ))}
                </Table>
            </MonitoringCol>
        );
    } else return <Col><p>Loading...</p></Col>;
};

export default LeaderInspection;
