import React from "react";
import {Button} from "ui/base/Button";
import helpers from "config/helpers";
import {ActionItem, NavIcon} from "ui/blocks/Header/Actions/ActionsStyled";
import {LogOutIcon} from "ui/icons";

const LogOutBtn = (props:any) => {
    return (<Button background={props.background}  onClick={() => {
        helpers.callSignout()
    }}> <ActionItem color={props.color}>
        Log out
        <NavIcon>
            <LogOutIcon/>
        </NavIcon>
    </ActionItem>
    </Button>)
};
export default LogOutBtn;
