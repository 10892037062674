import React from "react";
import Layout from "../ui/base/Layout/Layout";
import ProfileView from "../ui/blocks/profile/ProfileView/ProfileView";

const Profile = () => {
  return (
    <Layout title={"User Profile"}>
      <ProfileView />
    </Layout>
  );
};

export default Profile;
