import styled, {css} from "styled-components";
import {themeColor} from "../../theme";

const tableCss = (width: number) => {
    return css`
    td {
      &:first-child {
        width: ${`${width}px`};
      }
    }
  `;
};

export const TableStyled = styled.table<{ width?: number }>`
  background-color: #fefefe;
  border-spacing: 0;
  width: 100%;
  ${({width}) => width && tableCss(width)};
  strong {
    font-weight: 700;
  }

  tr {
    th {
      border: solid 1px ${themeColor("borderGrey")};
      border-top: none;
      border-bottom: none;
    }
  }
  tr,
  td {
    border: solid 1px ${themeColor("borderGrey")};

    &:first-child {
      td,
      th {
        border-top: none;
      }
    }
    td,
    th {
      padding: 14px 8px 14px 8px;
      color: ${themeColor("darkGrey")};
      font-size: 14px;
      &:not(:first-child) {
        border-left: 1px solid #f3f3f3;
      }
      &:not(:last-child) {
        border-right: 1px solid #f3f3f3;
      }
      &.nowrap {
        white-space: nowrap;
      }
    }
    a {
      color: ${themeColor("primary")};
      font-weight: 600;
    }
    th {
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      padding: 16px 14px 16px 24px;
      color: ${themeColor("blue")};
      span {
        color: ${themeColor("dark")};
        font-size: 14px;
      }
    }
  }
`;

export const TableHead = styled.thead<{ widthCol?: number, isDownload?: boolean, qtyCol?: number }>`
  background-color: ${themeColor("lightBlue")};
  color: ${themeColor("blue")};
  font-size: 20px;
  font-weight: 700;
  
  tr{
    &:first-child {
        td {
            color: ${themeColor("blue")};
        }
        
        td:first-child {
            width: ${({widthCol}) => widthCol}%;
            min-width: fit-content;
        }
    }
    
    &:last-child {
        td {
            width: calc(100% / ${({qtyCol}) => qtyCol});
        }
    }
}
`;

export const TableContent = styled.div``;
export const TableBody = styled.tbody<{
    widthCol?: number;
    isDownload?: boolean;
}>`
  tr {
    td {
      width: auto;
      min-width: fit-content;
      font-size: 14px;
      padding: 13px 24px;
      font-weight: 600;
      color: ${themeColor("darkGrey")};

      ${({isDownload}) =>
    isDownload && "a{font-style: italic; color: #0e5992;}"};

      &:first-child {
        ${({widthCol}) => widthCol && `width: ${widthCol}%`};
        min-width: fit-content;
      }
    }
  }


`;
export const TableFooter = styled.tfoot`
    width: 100%;
    padding: 13px 24px;
    tr th {
        span {
          font-size: 16px;
          line-height: 34px;
          font-weight: 600;
          text-transform: uppercase;
          color: #eb7c32;
        }
    
        a {
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #1c6eac;
          
        }
        font-size: 16px;
        font-weight: 500;
        color: #353535;
    }`;
