export const sampleColumn = 'sampleColumn'
export const analyticalColumn = 'analyticalColumn'


export const columnFirstSubheaders = ['Date', 'Outfall Identification']
export const tableStaticDate = {
	firstHeader: 'Sample Informations',
	secondHeader: 'Analytical Parameters'
}
export const footerRaw = ['Average', 'Annual NAL Value', 'Instantaneous Annual NAL Value' ]
export const footerStaticRaw = {
	anual: 'Annual NAL',
	minimum: 'Instantaneous Minimum NAL',
	avarage: 'pH'
}