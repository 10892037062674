import styled from "styled-components";

export const FacilityStyled = styled.div`
  padding: 24px 50px;
`;

export const FacilityRow = styled.div`
  display: flex;
  margin-bottom: 32px;
  flex-wrap: wrap;
  justify-content: space-between;
    overflow-x: auto;
`;

export const FacilityCol = styled.div<{ wide?: boolean, margin?: string }>`
  width: ${({wide}) => (wide ? "100%" : "calc(50% - 23px)")};
  margin: ${({margin}) => (margin ? margin : "0")};
  overflow-x:auto;

  @media (max-width: 1024px) {
    width:100%;
  }
`;
