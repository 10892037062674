import React from "react";

export const PrinterIcon = () => {
  return (
    <svg
      enableBackground="new 0 0 520 520"
      height="512"
      viewBox="0 0 520 520"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="_x37_38_x2C__Design_x2C__Designer_x2C__Graphic_x2C__Print_x2C__Printing">
        <g>
          <g>
            <g>
              <g>
                <path d="m370 190c-5.522 0-10-4.477-10-10v-95.316l-53.62-44.684h-146.38v140c0 5.523-4.477 10-10 10s-10-4.477-10-10v-150c0-5.523 4.477-10 10-10h160c2.34 0 4.604.82 6.401 2.318l60 50c2.28 1.9 3.599 4.714 3.599 7.682v100c0 5.523-4.478 10-10 10z" />
              </g>
              <g>
                <path d="m370 90h-60c-5.522 0-10-4.477-10-10v-50c0-5.523 4.478-10 10-10s10 4.477 10 10v40h50c5.522 0 10 4.477 10 10s-4.478 10-10 10z" />
              </g>
              <g>
                <path d="m490 410h-70c-5.522 0-10-4.478-10-10s4.478-10 10-10h60v-169.333c0-16.91-13.757-30.667-30.667-30.667h-378.666c-16.91 0-30.667 13.757-30.667 30.667v169.333h60c5.523 0 10 4.478 10 10s-4.477 10-10 10h-70c-5.523 0-10-4.478-10-10v-179.333c0-27.938 22.729-50.667 50.667-50.667h378.667c27.937 0 50.666 22.729 50.666 50.667v179.333c0 5.522-4.478 10-10 10z" />
              </g>
              <g>
                <path d="m420 500h-320c-5.523 0-10-4.478-10-10v-160c0-5.522 4.477-10 10-10h320c5.522 0 10 4.478 10 10v160c0 5.522-4.478 10-10 10zm-310-20h300v-140h-300z" />
              </g>
              <g>
                <path d="m360 380h-200c-5.523 0-10-4.478-10-10s4.477-10 10-10h200c5.522 0 10 4.478 10 10s-4.478 10-10 10z" />
              </g>
              <g>
                <path d="m360 420h-200c-5.523 0-10-4.478-10-10s4.477-10 10-10h200c5.522 0 10 4.478 10 10s-4.478 10-10 10z" />
              </g>
              <g>
                <path d="m360 460h-200c-5.523 0-10-4.478-10-10s4.477-10 10-10h200c5.522 0 10 4.478 10 10s-4.478 10-10 10z" />
              </g>
              <g>
                <path d="m90 270c-16.542 0-30-13.458-30-30s13.458-30 30-30 30 13.458 30 30-13.458 30-30 30zm0-40c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10z" />
              </g>
              <g>
                <path d="m170 270c-16.542 0-30-13.458-30-30s13.458-30 30-30 30 13.458 30 30-13.458 30-30 30zm0-40c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10z" />
              </g>
              <g>
                <path d="m250 270c-16.542 0-30-13.458-30-30s13.458-30 30-30 30 13.458 30 30-13.458 30-30 30zm0-40c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10z" />
              </g>
            </g>
            <g>
              <path d="m90 190c-5.523 0-10-4.477-10-10v-80c0-5.523 4.477-10 10-10h60c5.523 0 10 4.477 10 10s-4.477 10-10 10h-50v70c0 5.523-4.477 10-10 10z" />
            </g>
            <g>
              <path d="m430 190c-5.522 0-10-4.477-10-10v-70h-50c-5.522 0-10-4.477-10-10s4.478-10 10-10h60c5.522 0 10 4.477 10 10v80c0 5.523-4.478 10-10 10z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
