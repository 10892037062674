import React, {useState} from "react";
import {Table} from "ui/base/Table";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {WDID} from "mock/WDID";
import store from "ui/redux/store";

const REQUEST = gql`
query MyQuery ($wdid: String!) {
  portal_qry_era_status_current(where: {wdid: { _eq: $wdid }})  {
    wdid
    parameter_smarts
    criteria_type
    value
    era_status
    unit
  }
}
`;

type itemType = {
    wdid: string,
    parameter_smarts: string,
    criteria_type: string,
    value: string,
    era_status: string,
    unit: string,
}

const Nals = () => {
    const [newWDID, setNewWDID] = useState(WDID)
    const {loading, error, data} = useQuery(REQUEST, {
        variables: { wdid: newWDID },
    });

    store.subscribe(() => {
        setNewWDID(store.getState().wdid)
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <Table
            header={
                <tr>
                    <th align="center">Parameter</th>
                    <th align="center">Type</th>
                    <th align="center">Limit</th>
                    <th align="center">ERA Status</th>
                    <th align="center">Units</th>
                </tr>
            }
        >
            {data.portal_qry_era_status_current
                .filter((vals : itemType) => vals.wdid === newWDID)
                .map(({
                                                         wdid,
                                                         parameter_smarts,
                                                         criteria_type,
                                                         value,
                                                         era_status,
                                                         unit
                                                     }: itemType) =>  (
                    <tr key={wdid}>
                        <td align="center">
                            {parameter_smarts}
                        </td>
                        <td align="center">
                            {criteria_type}
                        </td>
                        <td align="center">
                            {value}
                        </td>
                        <td align="center">
                            {era_status}
                        </td>
                        <td align="center">
                            {unit}
                        </td>
                    </tr>
                )
            )}
        </Table>
    );
};

export default Nals;
