import styled from "styled-components";

export const PortalViewStyled = styled.div`
  padding: 24px 50px;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 32px;
  justify-content: space-between;
  @media(max-width:1024px) {
    flex-wrap: wrap;
  }
`;

export const Col = styled.div`
    width: calc(50% - 23px);
  @media(max-width:1024px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`;

export const Text = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: #353535;
    margin-left: 10px;
    text-transform: none;
    font-style: italic;
`


