import React from "react";
import {Title} from "ui/base/Title";
import {FacilityCol} from "ui/blocks/facility/FacilityView/FacilityStyled";
import {FacilityInfo} from "ui/blocks/facility/FacilityInfo";

const FacilityInfoCol = (props: any) => {

    const margin = props.margin

    return (
        <FacilityCol margin={margin}>
            <Title size={"h5"} position="left">
                Facility Info
            </Title>
            <FacilityInfo/>
        </FacilityCol>

    );
};

export default FacilityInfoCol;
