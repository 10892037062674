import styled from "styled-components";

export const ColStyled = styled.div<{ width?: number, textAlign?: string, display?: string }>`
  width: ${({width}) => width}%;
  ${({textAlign}) => textAlign ? `text-align: ${textAlign};` : ''}
  min-width: fit-content;
  padding-right: 15px;
  ${({display}) => display ? `display: ${display};` : ''}

  align-items: center;
  a {
    width: 100%;
    max-width: 178px;
    padding: 11px ;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    height:fit-content;
    margin-bottom: 37px;
  }
`;
