import styled from "styled-components";
import { ColorName, FontSize } from "../../theme";

interface TitleStyledProps {
  color?: ColorName;
  size?: FontSize;
  weight?: string;
  uppercase?: boolean;
  position?: "center" | "left" | "right";
}

export const TitleStyled = styled.div<TitleStyledProps>`
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.primary};
  font-size: ${({ theme, size }) =>
    size ? theme.fonts.sizes[size] : theme.fonts.sizes.big};
  text-align: ${({ position }) => (position ? position : "center")};
  font-weight: ${({ weight }) => (weight ? weight : "600")};
  ${({ uppercase }) => uppercase && "text-transform: uppercase"};
  margin-bottom:14px;
`;
