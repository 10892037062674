import styled from "styled-components";
import {ColorName, FontSize, themeColor} from "../../theme";

interface CheckBoxStyled {
    color?: ColorName;
    size?: FontSize;
    value?: boolean;
}

export const CheckBoxStyled = styled.p<CheckBoxStyled>`
    // display: flex;
    align-content: center;
    position:relative;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    color: ${themeColor("dark")};
    margin-bottom: 16px;
    
    a {
        margin: 0 4px;
        color: ${themeColor("primary")};
        &:hover {
             & + div {
                visibility: visible;
            }
        }
    }
    
    span{
        display: inline-block;
        width: 16px;
        height: 16px;
        background-color: #e4f0f9;
        margin-right: 11px;
        position:relative;
        margin-bottom: -3px;
        
        &::after{
            position: absolute;
            top: 8px;
            left: 2px;
            width: 7px;
            height: 1px;
            background-color: ${themeColor("primary")};
            transform: rotate(45deg);
            
        };
        &::before{
            position: absolute;
            top: 7px;
            left: 6px;
            width: 10px;
            height: 1px;
            background-color: ${themeColor("primary")};
            transform: rotate(-45deg);
        };
        ${({value}) => value && "&::after{content: ''}; &::before{content: '';};"};
    }

`;

export const CheckBoxStyledHover = styled.div`
    position: absolute;
    background-color: #e4f0f9;
    top: 100%;
    left: 45px;
    visibility: hidden;
    z-index:10;
    padding: 14px;
    max-width: 430px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #191919;
    
    a {
        margin: 0 4px;
        color: ${themeColor("primary")};
    }
    
    ul {
        margin-bottom: 10px;
        list-style-type: none;
        
        li {
            position: relative;
            padding-left: 7px;
            &::before {
                content:'';
                position: absolute;
                background-color: #000;
                border-radius:50%;
                width:2px;
                height:2px;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                
            }
        }
    }
`;
