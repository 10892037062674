import React, {useState} from "react";
import {Title} from "ui/base/Title";
import {Table} from "ui/base/Table";
import {Col} from "ui/blocks/portal/PortalView/PortalViewStyled";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {WDID} from "mock/WDID";
import store from "ui/redux/store";

const REQUEST = gql`
query MyQuery ($wdid: String!) {
  portal_qry_era_status_current(where: {wdid: { _eq: $wdid }}) {
    era_status
    parameter_smarts
    wdid
  }
}
`;

type itemType = {
    era_status: string,
    parameter_smarts: string,
    wdid: string
}

const EraStatusCol = () => {
    const [newWDID, setNewWDID] = useState(WDID)
    const {loading, error, data} = useQuery(REQUEST, {
        variables: {wdid: newWDID},
    });

    store.subscribe(() => {
        setNewWDID(store.getState().wdid)
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <Col>
            <Title size="h5" position="left">
                Exceedance Response Action (ERA) Status
            </Title>
            <Table
                header={
                    <tr>
                        <th align="left">Parameter</th>
                        <th align="left">ERA Status</th>
                    </tr>
                }
            >
                {data.portal_qry_era_status_current.map(({era_status, parameter_smarts}: itemType) => {
                    return <tr key={`${era_status}${parameter_smarts}`}>
                        <td>{parameter_smarts}</td>
                        <td>{era_status}</td>
                    </tr>
                })}
            </Table>
        </Col>
    );
};

export default EraStatusCol;
