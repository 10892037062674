export const fonts = {
  sizes: {
    small: "12px",
    regular: "15px",
    medium: "17px",
    big: "18px",
    biggest: "20px",
    h5: "22px",
    h4: "24px",
    h3: "30px",
    h2: "32px",
    h1: "48px",
  },
};
