import styled from "styled-components";

export const ProfileViewStyled = styled.div`
  padding: 32px 50px;
`;

export const Text = styled.p`
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #353535;
  width: fit-content;
  text-transform: none;
  font-style: italic;
  a {
    margin-left: 5px;
    color: #eb7c32;
  }
`;