import styled from "styled-components";
import {themeColor} from "../../../theme";

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  width: 33%;
  justify-content: end;
  @media (max-width: 1024px) {
    width: auto;
  }
`;

export const NavIcon = styled.div`
  width: 18px;
  height: 18px;
  margin-left: 8px;
  svg {
    width: 100%;
    height: 100%;
    fill: ${themeColor("grey")};
  }
  @media (max-width: 1024px) {
    margin-left: 0;
    margin-right: 8px;
  }
`;

export const ActionItem = styled.div<{ hidden?: boolean; color?: string }>`
  display: flex;
  align-items: center;
  color: ${({color}) => color ? color : themeColor('grey')};
  white-space: nowrap;
  &:last-child {
    margin-left: 50px;
  }
  @media (max-width: 1024px) {
      &:last-child {
      margin-top: 80px;
        margin-left: 0;
      }
    ${({hidden}) => hidden && "display: none"};
    width: auto;
    flex-direction: row-reverse;
    ${NavIcon} {
      svg {
        fill: ${({color}) => color ? color : themeColor('grey')};
      }}
  }
`;