import React, {Component} from "react";
import queryString from 'query-string';
import {AUTH_API_URL, CLIENT_ID, TIMETODOORDER} from "../config";
import jwt from "jwt-decode";
import {connect} from 'react-redux';
import Layout from "../ui/base/Layout/Layout";
import api from 'api';
import helpers from "../config/helpers";
import {Navigate} from "react-router-dom";

import {bindActionCreators} from "redux";
import * as LoginActions from 'ui/redux/actions/creators';

async function sendToken() {
    const parsed = queryString.parse(window.location.search);
    console.log(parsed);
    
    if (parsed.code !== undefined && parsed.state !== undefined) {
        const uniqueState = window.localStorage.getItem('authRequestState');
        console.log(uniqueState);
        if (parsed.state === uniqueState) {
            const fetchToken = async () => {
                const options = {
                    method: 'post',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    body: `grant_type=authorization_code&code=${parsed.code}&client_id=${CLIENT_ID}`
                };
                const response = await fetch(`${AUTH_API_URL}/token`, options);
                const data = response.json();
                console.log(data);
                return data;
            };
            const data = await fetchToken();
            const decodedToken = jwt(data.access_token);
            window.localStorage.setItem('accessToken', data.access_token);
            window.localStorage.setItem('refreshToken', data.refresh_token);
            window.localStorage.removeItem('authRequestState');
            return decodedToken;
        }
    }
}

class RedirectPage extends Component {
    constructor({props}: { props: any }) {
        console.log(props);
        super(props);
        this.state = {
            redirect: false,
            target: '/'
        };
    }
    
    

    componentDidMount() {
        sendToken().then((decodedToken: any) => {
            api.getOne('users', decodedToken!.id!, '')
                .then(function (this: any, user: any) {
                    if (!user.error.occur && user.data.length && user?.data[0]!.id!) {

                        const partnerCode = window.localStorage.getItem('partnerCode');
                        if (partnerCode) {
                            api.create('rpc/partners_assign', {user_id: user.data[0].id, partner_code: partnerCode})
                                .then(function (data) {
                                    window.localStorage.removeItem('partnerCode');
                                });
                        }
                        let loggedUser: any = {};
                        Object.assign(loggedUser, user.data[0]);
                        this.props.actions.login(loggedUser);
                    }
                    else {
                        helpers.callSignout();
                    }
                }.bind(this));
        })
    }

    render(this: any) {    
        console.log(this);
        
        return (
            <Layout title="">
                <div className="container">
                    <Navigate to={'/'}/>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state: any) => ({
    logged: state.logged.logged,
    user: state.logged.user,
    userVerifStep: state.logged.userVerificationStep,
});

const mapDispatchToProps = (dispatch: any) => ({
    actions: bindActionCreators(LoginActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RedirectPage);
