import React, { FC } from "react";
import { CheckBoxStyled, CheckBoxStyledHover } from "./CheckBoxStyled";
import { ColorName, Size } from "../../theme";

interface CheckBoxProps {
  color?: ColorName;
  size?: Size;
  value?: boolean;
  children: React.ReactNode;
  onClick?: any;
}

export const CheckBox: FC<CheckBoxProps> = (props) => {
  const { color, size , onClick, value, children } = props;
  return (
      <CheckBoxStyled
        color={color}
        size={size}
        value={value}
        onClick={onClick}
      >
        <span></span>
        {children}
          <CheckBoxStyledHover>
            <p>
              A Qualifying Storm Event (QSE) is one that:
            </p>
            <ul>
              <li>
                Produces a discharge for at least one drainage area; and
              </li>
              <li>
                Is preceded by 48 hours with no discharge from any drainage area.
              </li>
            </ul>
            <p>
              Samples from each discharge location shall be collected within four (4) hours of the start of the discharge or start of facility operations if the QSE occurs within the previous 12-hour period.
            </p>
            </CheckBoxStyledHover>
      </CheckBoxStyled>

  );
};
