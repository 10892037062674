import React from "react";
import {BannerStyled , Text , Button} from "./BannerStyled";

const Banner = () => {
    return (
        <BannerStyled>
            <Text>
                thank you for your message
            </Text>
            <Button href='/'>
                Go Home Page
            </Button>
        </BannerStyled>
    );
};

export default Banner;