import { defaultThemeColors } from "./default/colors";
import { fonts } from "./default/fonts";

export * from "./default/colors";
export * from "./default/fonts";

export interface WithIndex<T = string> {
  [key: string]: T;
}
export interface SizeMap<T = string> {
  small: T;
  medium: T;
  big: T;
}
export type Size = keyof SizeMap;
export type ColorNames = typeof defaultThemeColors;
export type ColorName = keyof ColorNames;
export type Colors = ColorNames & WithIndex;
export type Fonts = typeof fonts;
export type FontSize = keyof Fonts["sizes"];
export interface Theme {
  colors: Colors;
  fonts: Fonts;
}

export interface WithTheme {
  theme: Theme;
}

export const themeFontSize =
  (size: FontSize) =>
  ({ theme }: WithTheme) =>
    theme.fonts.sizes[size];

export const themeColor =
  (color: ColorName) =>
  ({ theme }: WithTheme) =>
    theme.colors[color];
