import React from "react";

export const FacilityInfoIcon = () => {
  return (
    <svg
      height="512pt"
      viewBox="0 -4 512.001 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m255.835938 503c-13.789063 0-26.5625-6.222656-35.0625-17.089844l-51.140626-64.855468-89.644531-.054688c-44.101562 0-79.988281-35.886719-79.988281-80v-261c0-44.113281 35.886719-80 80-80h352c44.113281 0 80 35.886719 80 80v161c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20v-161c0-22.054688-17.945312-40-40-40h-352c-22.054688 0-40 17.945312-40 40v261c0 22.054688 17.945312 40 40 40l99.351562.058594c6.121094.003906 11.902344 2.8125 15.691407 7.617187l57.191406 72.527344c1.167969 1.496094 2.59375 1.796875 3.605469 1.796875h.019531c1.007813-.003906 2.445313-.3125 3.5625-1.761719.035156-.046875.070313-.09375.105469-.136719l56.726562-72.433593c3.792969-4.839844 9.597656-7.667969 15.746094-7.667969h100c22.058594 0 40-17.945312 40-40 0-11.046875 8.953125-20 20-20s20 8.953125 20 20c0 44.113281-35.886719 80-80 80h-90.257812l-50.679688 64.707031c-8.453125 10.9375-21.21875 17.238281-35.046875 17.292969-.058594 0-.121094 0-.179687 0zm20.164062-198v-108c0-11.046875-8.953125-20-20-20s-20 8.953125-20 20v108c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm-20-197c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20 20-8.953125 20-20-8.953125-20-20-20zm0 0" />
    </svg>
  );
};
