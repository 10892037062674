import React, { ReactNode } from "react";
import {
  TableBody,
  TableContent,
  TableFooter,
  TableHead,
  TableStyled,
} from "./TableStyled";

interface TableProps {
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  body?: ReactNode;
  width?: number;
  widthCol?: number;
  qtyCol?: number;
  isDownload?: boolean;
}

const Table = ({ header, qtyCol, children, body, footer, width, widthCol, isDownload }: TableProps) => {
  return (
    <TableStyled width={width}>
      <TableHead qtyCol={qtyCol} widthCol={widthCol} >{header && header}</TableHead>
      <TableContent>{body}</TableContent>
      <TableBody isDownload={isDownload}>{children}</TableBody>
      <TableFooter>{footer && footer}</TableFooter>
    </TableStyled>
  );
};

export default Table;
