import React, {useState} from "react";
import {TMDLStyled} from "./TMDLStyled";
import {Table} from "ui/base/Table";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {WDID} from "mock/WDID";
import store from "ui/redux/store";

const REQUEST = gql`
query MyQuery ($wdid: String!) {
  portal_qry_facilitytmdl(where: {wdid: { _eq: $wdid }}, order_by: {compliance_due: asc, impaired_waterbody: asc, watershed_tmdl: asc}) {
    applicability
    chem_parameter
    compliance_due
    impaired_waterbody
    requirement
    watershed_tmdl
    wdid
  }
}
`;

type itemType = {
    applicability: string,
    chem_parameter: string,
    compliance_due: string,
    impaired_waterbody: string,
    requirement: string,
    watershed_tmdl: string,
    wdid: string,
}

function byField(field: string) {
    return (a: any, b: any) => {
        return new Date(a[field]) > new Date(b[field]) ? 1 : -1
    };
}

const TMDL = () => {
    const [newWDID, setNewWDID] = useState(WDID)
    const {loading, error, data} = useQuery(REQUEST, {
        variables: {wdid: newWDID},
    });

    store.subscribe(() => {
        setNewWDID(store.getState().wdid)
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <TMDLStyled>
            <Table
                width={190}
                header={
                    <tr>
                        <th align="center">Impaired Waterbody</th>
                        <th align="center">Associated TMDL</th>
                        <th align="center">Parameter</th>
                        <th align="center">Requirement</th>
                        <th align="center">Compliance Due</th>
                        <th align="center">Applicability</th>
                    </tr>
                }
                footer={
                    <tr>
                        {
                            data.portal_qry_facilitytmdl.length
                                ? null :
                                <th align='left' colSpan={6}>
                                    There are no applicable TMDLs in IGP Attachment E for the facility’s waterbody /
                                    watershed.
                                </th>
                        }
                    </tr>
                }
            >
                {data.portal_qry_facilitytmdl
                    .filter((vals: itemType) => vals.wdid === newWDID)
                    .sort(byField('compliance_due'))
                    .map(({
                              applicability,
                              chem_parameter,
                              compliance_due,
                              impaired_waterbody,
                              requirement,
                              watershed_tmdl,
                              wdid,
                          }: itemType, index: number, array: itemType[]) => {
                        return (
                            <tr key={wdid}>
                                {
                                    index > 0
                                    && impaired_waterbody === array[index - 1].impaired_waterbody
                                    && watershed_tmdl === array[index - 1].watershed_tmdl
                                        ? null :
                                        <td align="center"
                                            rowSpan={
                                                array.filter(
                                                    value =>
                                                        value.impaired_waterbody === impaired_waterbody
                                                        &&
                                                        value.watershed_tmdl === watershed_tmdl).length
                                            }>
                                            {impaired_waterbody}
                                        </td>
                                }
                                {
                                    index > 0
                                    && watershed_tmdl === array[index - 1].watershed_tmdl
                                    && impaired_waterbody === array[index - 1].impaired_waterbody
                                        ? null :
                                        <td align="center"
                                            rowSpan={
                                                array.filter(
                                                    (v) =>
                                                        v.impaired_waterbody === impaired_waterbody
                                                        &&
                                                        v.watershed_tmdl === watershed_tmdl).length
                                            }>
                                            {watershed_tmdl}
                                        </td>
                                }
                                <td align="center">
                                    {chem_parameter}
                                </td>
                                <td align="center">
                                    {requirement}
                                </td>

                                {
                                    index > 0
                                    && compliance_due === array[index - 1].compliance_due
                                        ? null :
                                        <td align="center"
                                            rowSpan={
                                                array.filter(
                                                    value => value.compliance_due === compliance_due).length}
                                        >
                                            {compliance_due}
                                        </td>
                                }
                                <td align="center">
                                    {applicability}
                                </td>
                            </tr>
                        )
                    })}
            </Table>
        </TMDLStyled>
    );
};

export default TMDL;
