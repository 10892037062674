import React, {useEffect, useState} from "react";
import {FacilitiesStyled} from "./FacilitiesStyled";
import {Table} from "ui/base/Table";
import store from "ui/redux/store";
import api from 'api';

type facUser = {
    id: number,
    user_id: number,
    facility_id: number,
    date_added: string,
}

type fac = {
    id: number,
    wdid: string,
    name: string,
    date_added: string
}

function byField(field: string) {
    return (a: any, b: any) => {
        return a[field] > b[field] ? 1 : -1
    };
}

const Facilities = () => {
    const [facUser, setFacUser] = useState<facUser[]>()
    const [load, setLoad] = useState(false)
    const [err, setError] = useState(null)
    const [facilities, setFacilities] = useState<fac[]>()

    const [user , setUser] =useState<facUser>(store.getState().user)
    store.subscribe(() => setUser(store.getState().user))

    useEffect(() => {
        if (user && user.id) {
            api.getSome('facilities2users', `?user_id=eq.${user.id}`)
                .then((res: any) => {
                    setFacUser(res.data)
                    api.get('facilities').then(
                        (res) => {
                            setFacilities(res.data)
                        }
                    )
                    setLoad(true)
                }).then((error: any) => {
                setError(error)
            })
        }
    }, [user])

    if (err) {
        return <div>Error:{err}</div>
    } else if (!load) {
        return <div>Loading...</div>
    } else if (facUser) {
        return (
            <FacilitiesStyled>
                <Table
                    widthCol={19}
                    header={
                        <tr>
                            <th align="left" colSpan={2}>
                                Facilities Linked to User Account
                            </th>
                        </tr>
                    }
                >
                    {facUser.map((value) => {
                        if (facilities) {
                            let facility = facilities.find((value_2) => value_2.id === value.facility_id)
                            if (facility) {
                                return (
                                    <tr key={facility.id}>
                                        <td>{facility.name}</td>
                                        <td>{facility.wdid}</td>
                                    </tr>
                                )
                            } else return (<tr></tr>)
                        }
                    })}
                </Table>
            </FacilitiesStyled>
        );
    } else return <div>not loaded</div>
};

export default Facilities;
