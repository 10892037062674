import React, {useEffect, useState} from "react";
import {
    ContactItem,
    ContactItemText,
    ContactWrapper,
    FormCol,
    FormRow,
    FormTitle,
    FormWrapper,
    InputStyled,
    Label,
    SelectStyled,
    SubmitBtn,
    TextAreaStyled,
    ContactContent,
    IconWrapper,
    ErrorFieldValidation, Text
} from "./ContactUsViewStyled";
import {ContactUsViewStyled} from "./ContactUsViewStyled";
import {Title} from "ui/base/Title";
import store from "ui/redux/store";
import {Formik, FormikErrors} from "formik";
import api from 'api'
import {Banner} from "./components";

type formValues = {
    name: string;
    email: string;
    phone: string;
    prefered_contact_method: string;
    message: string;
    link: string;
};

export interface FormValues {
    prefered_contact_method: string
}

const ContactUsView = () => {
    const [user, setUser] = useState(store.getState().user)
    const [selectedFile, setSelectedFile] = useState('');
    store.subscribe(() => setUser(store.getState().user))
    const {first_name, phone, email} = user
    const [isSubmit, setIsSubmit] = useState(false)
    const [isFile, setIsFile] = useState(false)
    const [fileName, setFileName] = useState('')
    const [formValues, setFormValues] = useState<formValues>({
        name: "",
        email: "",
        phone: '',
        prefered_contact_method: '',
        message: "",
        link: '',
    });
    const changeFieldValue = (key: string, val: string) => {
        setFormValues((prev) => {
            return {...prev, [key]: val};
        });
    };

    useEffect(() => {

        setFormValues(prev => ({...prev, name: first_name, email, phone}))

    }, [first_name, phone, email])

    const handleSubmit = async (values: any) => {
        setIsSubmit(true)
        if (isFile) {
            const formDataImg = new FormData();
            formDataImg.append('input', selectedFile);
            const fileUrl = await api.addOne(formDataImg);
            api.create('rpc/contact_us', {...values, link: fileUrl.data.url})
        } else api.create('rpc/contact_us', {...values})

    }
    const changeHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
        setFileName(event.target.files[0].name)
    };
    return (
        <ContactUsViewStyled>
            <Formik
                enableReinitialize={true}
                initialValues={{...formValues, ...{link: ""}}}
                onSubmit={handleSubmit}
                validate={(values: FormValues) => {
                    const errors: FormikErrors<FormValues> = {};
                    if (!values.prefered_contact_method) {
                        errors.prefered_contact_method = "* Please choose contact method";
                    }
                    return errors;
                }}
                render={({handleSubmit, errors, touched, setFieldValue}) => (
                    <FormWrapper onSubmit={handleSubmit}>
                        <FormTitle>
                            Questions? Please send us a message through the form below <br/>
                            and we’ll get back to you as soon as possible.
                        </FormTitle>
                        <FormRow>
                            <FormCol>
                                <InputStyled
                                    placeholder='Name'
                                    value={formValues.name}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        changeFieldValue("name", e.target.value);
                                        setFieldValue("name", e.target.value);
                                    }}
                                />
                            </FormCol>
                            <FormCol>
                                <InputStyled
                                    type='email'
                                    placeholder='Email'
                                    value={formValues.email}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        changeFieldValue("email", e.target.value);
                                        setFieldValue("email", e.target.value);
                                    }}
                                />
                            </FormCol>
                        </FormRow>
                        <FormRow>
                            <FormCol>
                                <InputStyled
                                    placeholder='Phone'
                                    value={formValues.phone}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        changeFieldValue("phone", e.target.value);
                                        setFieldValue("phone", e.target.value);
                                    }}
                                />
                            </FormCol>
                            <FormCol>
                                <SelectStyled
                                    value={formValues?.prefered_contact_method || ""}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        changeFieldValue("prefered_contact_method", e.target.value);
                                        setFieldValue("prefered_contact_method", e.target.value);
                                    }}
                                    warning={touched.prefered_contact_method && errors.prefered_contact_method}
                                >
                                    <option selected={true} hidden>
                                        Prefered Contact Method
                                    </option>
                                    <option value='email'>Email</option>
                                    <option value='phone'>Phone</option>
                                </SelectStyled>
                            </FormCol>
                        </FormRow>
                        <FormRow>
                            <TextAreaStyled
                                name='message'
                                placeholder='Message'
                                value={formValues.message}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setFieldValue("message", e.target.value);
                                    changeFieldValue("message", e.target.value);
                                }}
                            />
                        </FormRow>
                        <FormRow>
                            <Label htmlFor='file-input'>
                                <span>UPLOAD FILE</span>
                                <input onChange={(event) => {
                                    setIsFile(true)
                                    return changeHandler(event)
                                }
                                } name='link' type='file' id='file-input'/>
                            </Label>
                            <Text>{fileName}</Text>
                        </FormRow>
                        <FormRow>
                            <SubmitBtn type='submit'>Submit</SubmitBtn>
                        </FormRow>
                        {touched.prefered_contact_method && errors.prefered_contact_method ? (
                            <ErrorFieldValidation>
                                {errors.prefered_contact_method}
                            </ErrorFieldValidation>
                        ) : null}
                    </FormWrapper>
                )}></Formik>

            <ContactWrapper>
                <Title uppercase position='left' size='h3' weight='600'>
                    Contact info
                </Title>
                <ContactContent>
                    <ContactItem>
                        <IconWrapper></IconWrapper>
                        <ContactItemText>713.522.6300</ContactItemText>
                    </ContactItem>
                    <ContactItem>
                        <IconWrapper></IconWrapper>
                        <ContactItemText>
                            <a href='/home' target='_blank'>
                                inquiries@gsienv.com
                            </a>
                        </ContactItemText>
                    </ContactItem>
                </ContactContent>
            </ContactWrapper>
            {isSubmit ? <Banner/> : null}
        </ContactUsViewStyled>
    );
};

export default ContactUsView;