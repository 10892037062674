import React from "react";
import Layout from "ui/base/Layout/Layout";
import { ContactUsView } from "ui/blocks/ContactUsView";

const ContactUs = () => {
  return (
    <Layout title={"Contact us"}>
      <ContactUsView />
    </Layout>
  );
};

export default ContactUs;
