import styled from "styled-components";
import {themeColor} from "ui/theme";

export const SearchStyled = styled.div`
  width: 33%;
  position: relative;
  display: flex;
  align-items: center;
  svg {
    width: 20px;
    min-width: 20px;
    min-height: 20px;
    height: 20px;
    path {
      fill: #a3a3a3;
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
`;

export const Input = styled.input`
  min-width: 300px;
  width: 100%;
  font-size: 18px;
  border: none;
  padding: 5px 25px 5px 5px;
  border: 1px solid #a3a3a3;
  &:focus-visible {
    outline: none;
  }
`;

export const InputWrapper = styled.div<{
    isOpen?: boolean;
}>`
  border: 1px solid ${themeColor("grey")};
  position: relative;
  margin-left: 10px;
  visibility: visible;
  svg {
    transform: rotate(${({isOpen}) => (isOpen ? "180deg" : "0")});
  }
  @media (max-width: 1024px) {
    width: 200px;
  }
`;

export const DataList = styled.div<{ active?: boolean }>`
  position: absolute;
  z-index:10;
  top: 100%;
  left: -1px;
  border: 1px solid ${themeColor("grey")};
  background-color: #fff;
  max-height: 180px;
  width: calc(100% + 2px);
  overflow-y: auto;
  display: ${({active}) => (active ? "block" : "none")};
`;

export const Item = styled.div`
  padding: 10px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

export const Link = styled.a`
  text-transform: uppercase;
`;

export const LinkPage = styled.span`
  color: ${themeColor("grey")};
  font-size: 11px;
`;
