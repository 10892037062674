import jwt from "jwt-decode";
import { AUTH_API_URL, CLIENT_ID } from "./index";
import helpers from './helpers';


export const getToken = async (hard:any) => {
    const token:any = window.localStorage.getItem('accessToken');
    const refreshToken = window.localStorage.getItem('refreshToken');
    const decodedToken:any = jwt(token);
    const timeStamp = Math.round(Date.now()/1000) + 10;
    if (decodedToken.exp < timeStamp || hard) {
        window.localStorage.removeItem('accessToken');
        const sendRefresh = async () => {
            const params = {
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                body: `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${CLIENT_ID}`
            };
            const response = await fetch(`${AUTH_API_URL}/token`, params);
            const data = await response.json();
            return data.access_token;
        };
        const newToken = await sendRefresh();
        if((!newToken) || newToken===undefined) {
            helpers.callSignout();
        }
        else {
            window.localStorage.setItem('accessToken', newToken);
            return newToken;
        }
    } else {
        return token;
    }
};
