import React from "react";
import {ProfileViewStyled, Text} from "./ProfileViewStyled";
import {Title} from "ui/base/Title";
import {UserSummary} from "ui/blocks/profile/UserSummary";
import {Facilities} from "ui/blocks/profile/Facilities";


const ProfileView = () => {
    return (
        <ProfileViewStyled>
            <Title size={"h5"} position="left">
                User Profile: Contact example
                <Text>
                    To Edit your profile, please send us a message by clicking<a href='/contact-us'> "Get Help'</a>
                </Text>
            </Title>
            <UserSummary/>
            <Facilities/>
        </ProfileViewStyled>
    );
};

export default ProfileView;
