import styled from "styled-components";
import {themeColor, themeFontSize} from "ui/theme";

export const RequirementsWrapper = styled.div<{
    background?: string;
}>`
  padding: 20px;
  background-color: ${({background}) => background ? background : 'inherit'};
  width: 100%;
  ul {
    padding: 0;
    list-style-type: ">";
  }
  li {
    padding-left: 15px;

    a {
      color: #0e5992;
      font-size: ${themeFontSize("regular")};
      &:hover {
        color: ${themeColor("primary")};
      }
    }
  }
  a {
    cursor: pointer;
  }
`;

export const Text = styled.p`
    margin 14px 0;
     width: 100%;
`
