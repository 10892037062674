export const defaultThemeColors = {
  white: "#fff",
  black: "#000",
  lightBlue: "#dce9f3",
  blue: "#1c6eac",
  darkBlue: "#0b4673",
  primary: "#eb7c32",
  dark: "#191919",
  lightGrey: "#939393",
  grey: "#676767",
  darkGrey: "#353535",
  borderGrey: '#f8f8f8'
};
