import styled, { css } from "styled-components";
import { themeColor } from "ui/theme";
import { ButtonStyled } from "ui/base/Button/ButtonStyled";

const defaultInputCss = css`
  border: 1px solid ${themeColor("blue")};
  color: ${themeColor("darkBlue")};
  font-size: 14px;
  padding: 10px 15px;
  height: 42px;
  width: 100%;
  &::placeholder {
    color: ${themeColor("blue")};
  }
`;

export const ContactUsViewStyled = styled.div`
  padding: 24px 52px;
  display: flex;
  justify-content: space-between;
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 530px;
  padding-right: 15px;
  width: 100%;
`;

export const FormTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;
  color: ${themeColor("dark")};
  margin-bottom: 10px;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 20px;
`;

export const FormCol = styled.div<{ wide?: boolean }>`
  display: flex;
  width: ${({ wide }) => (wide ? "100%" : "50%")};
  &:first-child {
    margin-right: 20px;
  }
`;

export const TextAreaStyled = styled.textarea`
  display: flex;
  align-items: stretch;
  ${defaultInputCss};
  height: 142px;
`;

export const InputStyled = styled.input`
  display: flex;
  align-items: stretch;
  ${defaultInputCss};
`;

export const SelectStyled = styled.select<{ warning: string | false | undefined }>`
  display: flex;
  align-items: stretch;
  ${defaultInputCss};
  background-color: #fff;
  box-shadow: none;
  border: 1px solid ${p => (p.warning ? 'red' : themeColor("blue"))};
  -webkit-appearance: none;
`;

export const SubmitBtn = styled.button`
  display: flex;
  font-size: 16px;
  text-transform: uppercase;
  height: 46px;
  width: 138px;
  border: 1px solid #c2c2c2;
  text-align: center;
  padding: 5px 27px;
  background: #eb7c32;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

`;
export const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  height: 31px;
  width: 138px;
  border: 1px solid #0e5992;
  text-align: center;
  padding: 5px 27px;
  background-color: ${themeColor("lightBlue")};
  color: ${themeColor("black")};
  font-weight: 500;
  span {
    text-align: center;
  }
  input {
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
  }
`;

export const ContactWrapper = styled.div`
  width: 40%;
  padding-left: 63px;
  border-left: 4px solid #e3e3e3;
  padding-top: 110px;
`;

export const ContactContent = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const ContactItem = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 12px;
`;
export const ContactItemText = styled.div`
  font-size: 14px;
  color: ${themeColor("black")};
  a {
    color: ${themeColor("primary")};
  }
`;
export const  ErrorFieldValidation = styled.div`
  color: rgb(255,0,0);
`

export const Text = styled.p`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: 40px;
  font-weight: 400;
  font-size: 14px;
  color: #353535;

`;