import styled from "styled-components";
import {SearchStyled} from "ui/blocks/Header/Search/SearchStyled";

export const NavigationStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    width: 100%;
  }
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    padding: 8px 16px;
    a {
      width: auto;
    }
  }
`;

export const NavIcon = styled.div<{ height: number }>`
  height: ${({height}) => `${height / 10}vh`};
  margin-bottom: 1.2vh;
  svg {
    height: 100%;
    fill: white;
    width: auto;
  }
  @media (max-width: 1024px) {
    margin-bottom: 0;
    margin-right: 8px;
  }
`;

export const NavActions = styled.div`
  display: flex;
  align-items: center;
`;
export const NavItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3vh 8px;
  font-size: 1.7vh;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: ${({isActive, theme}) =>
    isActive ? theme.colors["primary"] : theme.colors["white"]};
  background-color: ${({isActive}) => isActive && "#0e5992"};
  border-left: 2px solid
    ${({isActive, theme}) =>
    isActive ? theme.colors["primary"] : "transparent"};
  ${NavIcon} {
    svg {
      ${({isActive, theme}) => isActive && `fill: ${theme.colors["primary"]}`}
    }
  }
  @media (max-width: 1024px) {
    display: ${({isActive}) => !isActive && "none"};
    flex-direction: row;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
  }
  
    ${SearchStyled} {
        display: ${({isActive}) => !isActive && "none"};
        z-index: 2000000;
    }
  
`;
export const NavList = styled.div<{ activeMenu: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1024px) {
  width:fit-content;
  }
  ${({activeMenu}) => activeMenu ? `
    position: fixed;
    top:0;
    right: 0;
    width: fit-content;
    height: 100%;
    background-color: #0e5992;
    padding-top: 40px;

    a{
        z-index: 999999;
    }

      ${NavItem} {
        @media (max-width: 1024px) {
            display: block;
            padding: 8px 40px;
        }
        ${NavIcon} {
            @media (max-width: 1024px) {
                margin: auto;
            }
        }
      }` : ''
}
`;

export const NavClose = styled.div<{ isActive: boolean }>`
   display: ${({isActive}) => !isActive && "none"};
    background-color: #0e5992;
    opacity: 0.3;
    z-index: 80;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`
